import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import shape1 from "../../../styletheme/assets/images/services/shape-1.png";
import shape2 from "../../../styletheme/assets/images/services/shape-2.png";
import shape3 from "../../../styletheme/assets/images/services/shape-3.png";
import shape4 from "../../../styletheme/assets/images/services/shape-4.png";
import shape5 from "../../../styletheme/assets/images/services/shape-5.png";
import project1 from "../../../styletheme/assets/images/projects/projects-1.jpg";
import PortfoliosSideBar from "../sidebar/portfoliosidebar";
import {
  fetchLatestPortfolioCategories,
  findPortfolioByCategoryName,
  fetchPortfoliosByPagination,
  fetchPortfolioWithSimilarTitle
} from "../../../services/portfolio/portfolioService";

import ReactPaginate from "react-paginate";

import PortfolioItemCard from "../../common/cards/portfolioitemcard";
import { toast } from "react-toastify";

//new
import { useGeolocated } from "react-geolocated";
import { getIpAddress } from "../../../services/analytics/pageVisitManager";
import { set } from "lodash";
import { getAdvertisementByWebPage } from "../../../services/advertisements/advertisementService";
import AdvertisementFullWidth from "../../common/advertisement/AdvertisementFullWidth";
import AdvertisementCompact from "../../common/advertisement/advertisementCompact";
import { handlePageViewStat } from "../../../services/utils/pageViewStats";


//TODO need to copy over the functionality from tutorials.jsx 

const Portfolioes = (props) => {

  const isDarkMode = props.themeName === "theme-dark";
  const containerClass = isDarkMode ? "pagination-dark" : "pagination-light";
  const subContainerClass = isDarkMode ? "pages pagination-dark" : "pages pagination-light";
  const activeClass = isDarkMode ? "active-dark" : "active-light";

//advert
const backgroundColor = isDarkMode ? '#333': '#f7f7f7'; 
const textColor = isDarkMode ? '#fff': '#333';


  const [isLoading, setIsLoading] = useState();
  const history = useNavigate();
  const { categoryName } = useParams();

  //needed for pagination and search
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageSize, setPageSize] = useState(6);

  const [portfolioCount, setPortfolioCount] = useState(0);


  const [portfolioes, setPortfolioes] = useState([
    {
      portfolioId: "",
      portfolioTitle: "",
      portfolioImageUrl: "",
      portfolioOverview: "",
      portfolioBackground: "",
      portfolioTags: [
        {
          tagId: "",
          tagName: "",
          tagDescription: "",
        },
      ],
      portfolioTechnologies: [
        {
          technologyId: "",
          technologyName: "",
          technologyDescription: "",
        },
      ],
      commentCount: 0,
      authorName: "",
      authorProfile: {
        authorName: "",
        authorProfileImgUrl: "",
        authorProfileOverview: "",
      },
      portfolioCategories: [
        {
          categoryName: "",
          categoryDescription: "",
        },
      ],
      portfolioSectionSnippets: [
        {
          snippetTitle: "",
          snippetExplanation: "",
          snippetMedias: [
            {
              mediaId: "",
              mediaUrl: "",
              mediaCaption: "",
              mediaType: "",
            },
          ],
        },
      ],
      portfolioHighlightedFeatureSection: [
        {
          sectionId: "",
          sectionTitle: "",
          sectionExplanation: "",
          portfolioSectionSnippets: [
            {
              snippetTitle: "",
              snippetExplanation: "",
              snippetMedias: [
                {
                  mediaId: "",
                  mediaUrl: "",
                  mediaCaption: "",
                  mediaType: "",
                },
              ],
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: "",
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: "",
              authorProfileUrl: "",
              authorTitle: "",
            },
          ],
          referenceDetail: "",
        },
      ],
      marketingInfo: {
        mediaType: "",
        mediaUrl: "",
        webLink: "",
        androidLink: "",
        appleLink: "",
        youtubeChannelLink: "",
        facebookPageLink: "",
        twitterProfileLink: "",
      },
      recordCreatedDate: "",
      isDraft: false,
    },
  ]);

  const [portfolioCategories, setPortfolioCategories] = useState([
    {
      categoryId: "1",
      categoryName: "All Portfolioes",
      categoryDescription: "Refresh the list to show all my portfolio pieces",
    },
  ]);

  const [advertisement, setAdvertisement] = useState({
    advertisementId: "",
    advertPlacement: {
      website: {
        websitePage: "",
      },
      mobileApp: {
        mobileAppPage: "",
      },
    },
    isCompactAdvertisement: false,
    advertisementImageUrl: "",
    advertisementLink: "",
    advertisementDescription: "",
    advertisementTitle: "",
    advertisementStartDate: "",
    advertisementEndDate: "",
    advertisementIsActive: false,
    recordCreatedDate: "",
  });

  const [advertisementFullWidth, setAdvertisementFullWidth] = useState({
    advertisementId: "",
    advertPlacement: {
      website: {
        websitePage: "",
      },
      mobileApp: {
        mobileAppPage: "",
      },
    },
    isCompactAdvertisement: false,
    advertisementImageUrl: "",
    advertisementLink: "",
    advertisementDescription: "",
    advertisementTitle: "",
    advertisementStartDate: "",
    advertisementEndDate: "",
    advertisementIsActive: false,
    recordCreatedDate: "",
  });

  useEffect(() => {
    //set at the top of the screen
    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      handlePageViewStat("Portfolios","","","",props.themeName,categoryName,searchValue,
      currentPage,pageSize);
    }

    getPortfolioPieces();

    getPortfolioCategories();


    getAdvertisements();

  }, [currentPage]);


  //advertisement
  const getAdvertisements = async () => {
    try {
  
      //Compact
      //get the advertisements from function getAdvertByWebPage(webpage,isCompactAdvertisement) 
       getAdvertisementByWebPage("portfolios","true")
       .then((response) => {
        //console.log("Advertisement");
        const result = response.data;
       
  
        if(result !== "")
        {
  
         // console.log("Compact Advertisement ");
         // console.log(result);
          setAdvertisement(result);
        }
        else
        {
          //console.log("No Compact Advertisement Found");
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
  
  
      //get the fullwidth advertisement for tutorial detail page
      getAdvertisementByWebPage("portfolios","false")
      .then((response) => {
      
       const result = response.data;
      
  
       if(result !== "")
       {
  
        // console.log("Full Width Advertisement");
         //console.log(result);
         setAdvertisementFullWidth(result);
       }
       else
       {
         //console.log("No Full Width Advertisement Found");
       }
       
     })
     .catch((error) => {
       console.log(error);
     });
  
  
    } catch (error) {
      console.log("Error fetching advertisements:", error);
    }
  };

  //continue here
  const getPortfolioPieces = async () => {

    //TODO use this fetchPortfoliosByPagination for pagination

    //if categoryName found then filter, if not fetch all
    setIsLoading(true);
    if (categoryName !== undefined) {
      //console.log("Filter by " + categoryName);
      const { data: portfolioes } = await findPortfolioByCategoryName(
        categoryName
      );
      //console.log("Count: " + portfolioes.length);
      if (portfolioes !== undefined && portfolioes.length !== 0){
        setPortfolioes(portfolioes);

        //set the portfolio count
        setPortfolioCount(portfolioes.length);

        //set the page count


      }
      else toast.warning("No Portfolio Item Found");

      setIsLoading(false);
    } else if (categoryName === undefined) {
      //console.log("Fetch All");

      //using pagination
     
      getPortfolioPiecesByPagination();

    }

  
  };

  const getPortfolioCategories = async () => {
    const limit = 10;
    const { data: portfolioCategories } = await fetchLatestPortfolioCategories(
      limit
    );

    if (portfolioCategories !== undefined)
    {
      //set default first category to be all
      const allCategory = [
        {     
          categoryId: "1",
          categoryName: "All Portfolioes",
          categoryDescription: "Refresh the list to show all my portfolio pieces",},
          ...portfolioCategories
      ]

      setPortfolioCategories(allCategory);

    }

  };

  const updateUI = async (value) => {
    history("/portfolio");

    setIsLoading(true);

    getPortfolioPiecesByPagination();

  };

  const getPortfolioPiecesByPagination = async () => {


      fetchPortfoliosByPagination(currentPage, pageSize)
      .then((response) => {
        //console.log(response.data);

        const {portfolioes, pageCount} = response.data;

        //console.log(`Portfolioes: ${portfolioes}`);

        if (portfolioes !== undefined && portfolioes.length !== 0){
          setPortfolioes(portfolioes);
          setPageCount(pageCount);
          setPortfolioCount(portfolioes.length);

        }
        else toast.warning("No Portfolio Item Found");

       
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error Fetching Portfolioes");
        setIsLoading(false);
      });

  };

  const updateAndFilterItemsUI = async (value) => {
    setIsLoading(true);

    if (value !== undefined && value !== "") {
      history(`/portfolio/${value.categoryName}`);

      const { data: portfolioes } = await findPortfolioByCategoryName(
        value.categoryName
      );
      //console.log("Count: " + portfolioes.length);
      if (portfolioes !== undefined && portfolioes.length !== 0)
       {
         setPortfolioes(portfolioes);
         
          //set the portfolio count
          setPortfolioCount(portfolioes.length);

       }
       else if (value.categoryName === "All Portfolioes") {
        //console.log("Fetch All");
        //using pagination
        getPortfolioPiecesByPagination();

      }
      else toast.warning("No Portfolio Item Found");

      setIsLoading(false);
    }

    setIsLoading(false);
  };


  //new 
  const handleSearchValue = e => {
    const { value } = e.target;
    setSearchValue(value);
    setCurrentPage(0);
  };

  const handleSubmitSearch = () => {
    fetchPortfolioWithSimilarTitle(searchValue)
      .then(response => {
        const portfolios = response.data;

        //clear the searchValue
        setSearchValue("");

        //console.log("Portfolios");
        //console.log(portfolios);

        setPortfolioes(portfolios);

        setPortfolioCount(portfolios.length);

        if (portfolios.length === 0) {
           getPortfolioPiecesByPagination();;
          
        }
      })
      .catch(e => {
        console.log(e);
      });  

  };




  return (
    <React.Fragment>
      {/*Start Preloader Area */}
      {isLoading && (
        <div className="preloader-area">
          <div className="spinner">
            <div className="inner">
              <div className="disc"></div>
              <div className="disc"></div>
              <div className="disc"></div>
            </div>
          </div>
        </div>
      )}
      {/* End Preloader Area -->*/}

      <section className="services-area pt-100 pb-70">
        {/*<!-- Start Page Banner Area -->*/}
        <div className="page-banner-area">
          <div className="container">
            <div className="page-banner-content">
              <h2>Portfolios</h2>

              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Portfolios</li>
              </ul>
            </div>
          </div>

          <div
            className="page-banner-shape-1"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape1} alt="image" />
          </div>
          <div
            className="page-banner-shape-2"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape2} alt="image" />
          </div>
          <div
            className="page-banner-shape-3"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape3} alt="image" />
          </div>
          <div
            className="page-banner-shape-4"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape4} alt="image" />
          </div>
        </div>
        {/*<!-- End Page Banner Area -->*/}

        {/*<!-- Start Projects Area -->*/}
        <div className="courses-area ptb-100">
          <div className="container">

          <div className="plod-grid-sorting row align-items-center">
              <div className="col-lg-6 col-md-6 result-count">
                <p>
                  We found <span className="count">{portfolioCount}</span>{" "}
                  of my portfolios 
                </p>
              </div>
            </div>
          <div className="col-md-8">
                <div className="input-group mb-3" >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by title"
                    value={searchValue}
                    
                    onChange={handleSearchValue}
                  />
                  &nbsp;
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={handleSubmitSearch}
                    >
                      Search
                    </button>
                  </div>
                </div>
             
          
            </div>
            <div className="row">
    <div className="col-lg-8 col-md-8">



        <div className="row">
          {portfolioes?.map((value, index) => (
            <React.Fragment key={index}>
              <PortfolioItemCard
                
                  portfolioImageUrl={value.portfolioImageUrl}
                  portfolioTitle={value.portfolioTitle}
                  portfolioOverview={value.portfolioOverview}
                  onClick={() => updateUI(value)}
                  path={`/portfoliodetail/${value.portfolioTitle}`}
                  itemCount={portfolioes.length}
                />
              </React.Fragment>
          ))}
        </div>

        <div className="col-lg-12 col-md-12">
                  {pageCount > 1 && (
                  <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(data) => {
                      const selectedPage = data.selected + 1;
                      setCurrentPage(selectedPage);
                    }}
                    containerClassName={containerClass}
                    subContainerClassName={subContainerClass}
                    activeClassName={activeClass}
                  />
                  )}
                 

        </div>
        

        {advertisementFullWidth.advertisementTitle && advertisementFullWidth.isCompactAdvertisement == false &&
        (
            <AdvertisementFullWidth

            appDescription={advertisementFullWidth.advertisementDescription}
            appStoreLink={advertisementFullWidth.advertisementLink}
            appName={advertisementFullWidth.advertisementTitle}
            backgroundColor={backgroundColor}
            textColor={textColor}
            screenshot={advertisementFullWidth.advertisementImageUrl}

        />
        )
        }


      </div>
              {/*Side Bar Start exact this*/}
              <div className="col-lg-4 col-md-12" style={{ float: "right" }}>
                <section className="widget-area">
                  {/*Latest Tutorials start   updateAndFilterItemsUI*/}

                  {portfolioCategories && (
                    <React.Fragment>
                      <div className="widget widget_search" hidden>
                        <form className="search-form">
                          <input
                            type="search"
                            className="search-field"
                            placeholder="Search Something"
                          />
                          <button type="submit">
                            <i className="ri-search-line"></i>
                          </button>
                        </form>
                      </div>

                      {/* Compact Advertisement */}

                      {advertisement.advertisementTitle && advertisement.isCompactAdvertisement == true &&
            <AdvertisementCompact
                screenshot={advertisement.advertisementImageUrl}
                backgroundColor={backgroundColor}
                textColor={textColor}
                sectionHeight={'320px'}
                appName={advertisement.advertisementTitle}
                appDescription={advertisement.advertisementDescription}
                appStoreLink={advertisement.advertisementLink}
            />
            }
                      {/* Compact Advertisement */}

                      <div className="widget widget_tag_cloud">
                        <h3 className="widget-title">Portfolio Tags</h3>

                        <div className="tagcloud">
                          {portfolioCategories.map((value, index) => (
                            <Link
                              key={index}
                              to="#"
                              onClick={() => updateAndFilterItemsUI(value)}
                            >
                              {value.categoryName}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </section>
              </div>
              {/*Side Bar End*/}

         
            </div>
          </div>

          <div
            className="projects-shape-1"
            data-speed="0.09"
            data-revert="true"
          >
            <img src={shape1} alt="image" />
          </div>
          <div
            className="projects-shape-2"
            data-speed="0.09"
            data-revert="true"
          >
            <img src={shape2} alt="image" />
          </div>
          <div
            className="projects-shape-3"
            data-speed="0.09"
            data-revert="true"
          >
            <img src={shape3} alt="image" />
          </div>
        </div>

        {/*<!-- End Projects Area -->*/}
      </section>
    </React.Fragment>
  );
};

export default Portfolioes;
