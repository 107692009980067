
import React from 'react'
import { Link } from 'react-router-dom';
import courses1 from "../../../styletheme/assets/images/courses/courses-1.jpg"
import image1 from "../../../styletheme/assets/images/courses/images-1.jpg"
import AdvertisementCompact from '../../common/advertisement/advertisementCompact';



const TutorialSideBar = ({tutorial,backgroundColor,textColor,advertisement}) =>{
    //make reusable as well
   // const screenshot = "https://img.freepik.com/free-photo/marketing-creative-collage-with-phone_23-2149379900.jpg?w=900&t=st=1685886661~exp=1685887261~hmac=2da353a0ed62b5969d2919d8718b060844f6b2b285357dc91e80958d0582b6f1";
    const screenshot = advertisement.advertisementImageUrl;
    
    const sectionHeight = '320px';
    const appName = advertisement.advertisementTitle;
    const appDescription = advertisement.advertisementDescription;
    const appStoreLink = advertisement.advertisementLink;

    
    return (
        

        <React.Fragment>
            <div className="widget widget_search" hidden>
                <form className="search-form">
                    <input type="search" className="search-field" placeholder="Search Something"/>
                    <button type="submit"><i className="ri-search-line"></i></button>
                </form>
            </div>

            {advertisement.advertisementTitle && advertisement.isCompactAdvertisement == true &&
            <AdvertisementCompact
                screenshot={screenshot}
                backgroundColor={backgroundColor}
                textColor={textColor}
                sectionHeight={sectionHeight}
                appName={appName}
                appDescription={appDescription}
                appStoreLink={appStoreLink}
            />
            }

            <div className="widget widget_tag_cloud ">
                <h3 className="widget-title">Tutorial Categories</h3>

                <div className="tagcloud">
                
                    {tutorial.tutorialCategories.map((value,index)=>(

                        
                       
                            <Link key={index} to="#">{value.categoryName}</Link>
                       
                    
                    ))}
                </div>
            </div>

    

            <div className="widget widget_tag_cloud">
                <h3 className="widget-title">Tutorial Tags</h3>
                
                <div className="tagcloud">
                    {tutorial.tutorialTags.map((value,index)=>(

                        <Link key={index} to="#">{value.tagName}</Link>


                    ))}
                                                        
                    
                </div>
            </div>
        </React.Fragment>
    );
}

export default TutorialSideBar;
