import http from "../general/httpService";
import api from "../../constants/config.json";


export async function getIpAddress() {
    try {
      const response = await http.get("https://api.ipify.org?format=json");
      //console.log(response);

      return response;
    } catch (error) {
      return null;
    }
};
  

  //pageViewStatManagement/api/create_new_pageViewStat
export function createNewPageViewStat(pageViewStat) {
  const fullApiEndpoint =
    api.apiEndpoint + "/pageViewStatManagement/api/create_new_pageViewStat";
  return http.post(fullApiEndpoint, pageViewStat);
}



