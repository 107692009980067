import { useEffect, useState } from "react";
import ClipboardJS from "clipboard";
//import "prismjs/themes/prism.css";
import "prismjs/themes/prism-twilight.min.css";
import Prism from "prismjs";
import "prismjs/plugins/line-numbers/prism-line-numbers.min.js";
//import the sourcecodebutton.css file
import "prismjs/plugins/show-language/prism-show-language.min.js";
import "../../../styletheme/other/sourcecodebutton.css";
//import toastify css file
import { toast } from "react-toastify";


const CodeSnippet = ({ code, language,id }) => {
    //const id = `code-${Math.floor(Math.random() * 1000000)}-${Date.now()}`;
  const [isClipboardSupported, setIsClipboardSupported] = useState(false);

  useEffect(() => {
    setIsClipboardSupported(!!navigator.clipboard);
  }, []);


  useEffect(() => {
    const clipboard = new ClipboardJS(".copy-button");

    clipboard.on("success", (e) => {
      e.clearSelection();
      
      //const id = e.trigger.getAttribute("data-clipboard-target");
     

    });

    return () => {
        
      clipboard.destroy();
    };
  }, [id, isClipboardSupported]);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <pre className="line-numbers ">
       
      <code id={id} className={`language-${language}`}>
        {code}
      </code>
      {isClipboardSupported && (
      <button className="copy-button" onClick={() =>{ toast.info(`Code copied to clipboard!`);}}  data-clipboard-target={`#${id}`}>
        Copy 
      </button>
        )}
    </pre>
  );
};

export default CodeSnippet;