import logo from "./logo.svg";
import "./App.css";
import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
//import "../src/styletheme/other/menu/gototop.css";
//To set another port
//https://stackoverflow.com/questions/40714583/how-to-specify-a-port-to-run-a-create-react-app-based-project
import NavBar from "./components/common/navigation/navigationbar";
//import ProtectedRoute from "./components/authentication/protectedroute";
//pages
import HomePage from "./components/publicpages/homepage";
import NotFound from "./components/error/notfound";
import Tutorials from "./components/publicpages/tutorial/tutorials";
import TutorialDetail from "./components/publicpages/tutorial/tutorialdetail";
import GeneralFooter from "./components/common/navigation/generalfooter";
import { fetchLatestTutorials } from "../src/services/tutorials/tutorialService";
import ContactMe from "./components/publicpages/contactme";
import AboutMe from "./components/publicpages/about/aboutme";
import PortfolioDetail from "./components/publicpages/portfolio/portfolioDetail";
import Portfolioes from "./components/publicpages/portfolio/portfolioes";
import Blogs from "./components/publicpages/blog/blogs";
import BlogDetail from "./components/publicpages/blog/blogdetail";
import { fetchLatestBlogs } from "./services/blog/blogsService";

class App extends Component {

  //TODO: NEXT NEED TO UPDATE ALL THE PACKAGES TO BY THE MOST RECENT VERSIONS then re-test the website
  
  state = {
    themeName: localStorage.getItem("theme") || "theme-light",
    currentProfile: [
      {
        firstName: "",
        lastName: "",
        emailAddress: "",
        adminId: "",
      },
    ],
    admin: [],
    latestTutorials: [
      {
        tutorialId: "",
        tutorialTitle: "",
        videoUploadDate: "",
        youTubeVideoCode: "",
        tutorialOverview: "",
        tutorialPromoImage: "",
        tutorialTags: [
          {
            tagId: "",
            tagName: "",
            tagDescription: "",
          },
        ],

        commentCount: 0,
        authorName: "",
        authorProfile: {
          authorName: "",
          authorProfileImgUrl: "",
          authorProfileOverview: "",
        },
        tutorialCategories: [
          {
            categoryName: "",
            categoryDescription: "",
          },
        ],
        tutorialSections: [
          {
            sectionId: "",
            sectionTitle: "",
            sectionSectionExplanation: "",
            sourceCodeSnippets: [
              {
                sourceCodeTitle: "",
                sourceCodeExplanation: "",
                sourceCodeDetail: "",
                languageId: "",
                languageName: "",
              },
            ],
          },
        ],
        references: [
          {
            referenceTitle: "",
            referenceYear: 0,
            referenceAuthors: [
              {
                authorName: "",
                authorProfileUrl: "",
                authorTitle: "",
              },
            ],
            referenceDetail: "",
          },
        ],
        recordCreatedDate: "",
        isDraft: false,
      },
    ],
    latestBlogs: [
      {
        blogId: "",
        blogTitle: "",
        blogImageUrl: "",
        blogOverview: "",
        blogTags: [
          {
            tagId: "",
            tagName: "",
            tagDescription: "",
          },
        ],
        commentCount: 0,
        authorName: "",
        authorProfile: {
          authorName: "",
          authorProfileImgUrl: "",
          authorProfileOverview: "",
        },
        blogCategories: [
          {
            categoryName: "",
            categoryDescription: "",
          },
        ],
        blogSections: [
          {
            sectionId: "",
            sectionTitle: "",
            sectionExplanation: "",
            blogSectionSnippets: [
              {
                snippetTitle: "",
                snippetExplanation: "",
                snippetMedias: [
                  {
                    mediaId: "",
                    mediaUrl: "",
                    mediaCaption: "",
                    mediaType: "",
                  },
                ],
              },
            ],
          },
        ],
        references: [
          {
            referenceTitle: "",
            referenceYear: 0,
            referenceAuthors: [
              {
                authorName: "",
                authorProfileUrl: "",
                authorTitle: "",
              },
            ],
            referenceDetail: "",
          },
        ],
        isDraft: false,
      },
    ],
  };

  async componentDidMount() {
    this.themeSwitcher();
    //Called on page load
    /*const admin = getCurrentUser()

    this.setState({ admin })
    //get the admin profile here
    const { data: profile } = await getCurrentUserProfile()
    console.log('Profle')

    const currentProfile = [
      {
        firstName: profile.firstName,
        lastName: profile.lastName,
        emailAddress: profile.emailAddress,
        adminId: profile.adminId,
      },
    ]
    console.log(currentProfile)

    this.setState({ currentProfile })*/
    //show footer when admin == null or not signed in
    //fetch latest
    const { data: latestTutorials } = await fetchLatestTutorials(2);
    //console.log(latestTutorials);
    if (latestTutorials !== "") this.setState({ latestTutorials });

    //get latest blogs
    const limit = 2;
    const { data: latestBlogs } = await fetchLatestBlogs(limit);
    //const {data: latestBlogs}  = await fetchAllBlogs();
    if (latestBlogs !== undefined) this.setState({ latestBlogs });
  }

  toggleTheme() {
    if (localStorage.getItem("theme") === "theme-dark") {
      this.setTheme("theme-light");
    } else {
      this.setTheme("theme-dark");
    }
  }

  // function to set a given theme/color-scheme
  setTheme(themeName) {
    localStorage.setItem("theme", themeName);
    document.documentElement.className = themeName;

    //console.log("Theme Name: " + themeName);
    this.setState({ themeName });

  }
  // function to toggle between light and dark theme

  // Immediately invoked function to set the theme on initial load
  themeSwitcher() {
    if (localStorage.getItem("theme") === "theme-dark") {
      this.setTheme("theme-dark");
      document.getElementById("slider").checked = false;
    } else {
      this.setTheme("theme-light");
      document.getElementById("slider").checked = true;
    }
  }

  

  render() {
    const { admin, currentProfile, latestTutorials, latestBlogs } = this.state;

    return (
      <React.Fragment>
        <body>
          <ToastContainer />
          {/* Navigation Bar Start */}
          <NavBar admin={admin} currentProfile={currentProfile} />

          {/* Navigation Bar End */}
          <div className="content">
            <Routes>
              <Route path="/" element={<HomePage themeName={this.state.themeName} />} />

              <Route path="/aboutme" element={<AboutMe />} />
              <Route path="/contactme" element={<ContactMe />} />

              <Route path="/blogs" element={<Blogs themeName={this.state.themeName} />}>
                <Route path=":categoryName" element={<Blogs themeName={this.state.themeName} />} />
              </Route>

              <Route path="/portfolio" element={<Portfolioes themeName={this.state.themeName} />}>
                <Route path=":categoryName" element={<Portfolioes themeName={this.state.themeName} />} />
              </Route>

              <Route path="/portfoliodetail" element={<PortfolioDetail themeName={this.state.themeName} />}>
                <Route path=":portfolioTitle" element={<PortfolioDetail themeName={this.state.themeName} />} />
              </Route>

              <Route path="/blogdetail" element={<BlogDetail themeName={this.state.themeName}  />}>
                <Route path=":blogTitle" element={<BlogDetail themeName={this.state.themeName}  />} />
              </Route>

              <Route path="/tutorials" element={<Tutorials themeName={this.state.themeName} />}>
                <Route path=":categoryName" element={<Tutorials themeName={this.state.themeName} />} />
              </Route>

              <Route path="/tutorialdetail" element={<TutorialDetail themeName={this.state.themeName} />}>
                <Route path=":tutorialTitle" element={<TutorialDetail themeName={this.state.themeName} />} />
              </Route>
              <Route path="/notfound" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
  {/* your footer content goes here */}
 
          {/*dark version */}
          <div class="dark-version">
            <label id="switch" class="switch">
              <input
                type="checkbox"
                onChange={() => this.toggleTheme()}
                id="slider"
              />
              <span class="slider round"></span>
            </label>
          </div>
          {/*dark version */}
      
          <GeneralFooter
            latestTutorials={latestTutorials}
            latestBlogs={latestBlogs}
          />
      
        </body>
      </React.Fragment>
    );
  }
}

export default App;
