import http from "../general/httpService";
import api from "../../constants/config.json";

export function fetchAboutMe() {
	const fullApiEndpoint =
		api.apiEndpoint + "/aboutManagement/api/fetch_about_me";
	return http.get(fullApiEndpoint);
}


//find author by name
export function fetchAboutAuthorByName(aboutAuthorName) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/aboutManagement/api/fetch_about_author_by_name/" +
		aboutAuthorName;
	return http.get(fullApiEndpoint);
}