import React, { Component, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../../styletheme/assets/logo/logo.png";
import styled from "styled-components";
import "../../../index.css";

const NavBar = ({ admin, currentProfile }) => {
  //On load get the profile info from me
  //mobile start
  const COLORS = {
    primaryDark: "#2f55d4",
    primaryLight: "#B6EDC8",
  };

  const MenuLabel = styled.label`
    background-color: ${COLORS.primaryDark};
    position: fixed;
    top: 3rem;
    right: 1rem;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    cursor: pointer;
    z-index: 1000;

    box-shadow: 0 1rem 3rem rgba(182, 237, 200, 0.3);
    text-align: center;
  `;

  const NavBackground = styled.div`
    position: fixed;
    top: 6.5rem;
    right: 6.5rem;
    background-image: radial-gradient(
      ${COLORS.primaryDark},
      ${COLORS.primaryLight}
    );
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    z-index: 600;
    transform: ${(props) => (props.clicked ? "scale(80)" : "scale(0)")};
    transition: transform 0.8s;
  `;

  const Icon = styled.span`
    position: relative;
    background-color: ${(props) => (props.clicked ? "transparent" : "white")};
    width: 1.5rem;
    height: 1px;
    display: inline-block;
    margin-top: 1.5rem;
    transition: all 0.3s;
    &::before,
    &::after {
      content: "";
      background-color: white;
      width: 1.5rem;
      height: 1px;
      display: inline-block;
      position: absolute;
      left: 0;
      transition: all 0.3s;
    }
    &::before {
      top: ${(props) => (props.clicked ? "0" : "-0.8rem")};
      transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
    }
    &::after {
      top: ${(props) => (props.clicked ? "0" : "0.8rem")};
      transform: ${(props) =>
        props.clicked ? "rotate(-135deg)" : "rotate(0)"};
    }
    ${MenuLabel}:hover &::before {
      top: ${(props) => (props.clicked ? "0" : "-1rem")};
    }
    ${MenuLabel}:hover &::after {
      top: ${(props) => (props.clicked ? "0" : "1rem")};
    }
  `;

  const Navigation = styled.nav`
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 600;
    width: ${(props) => (props.clicked ? "100%" : "0")};
    opacity: ${(props) => (props.clicked ? "1" : "0")};
    transition: width 0.8s, opacity 0.8s;
  `;

  const List = styled.ul`
    position: absolute;
    list-style: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  `;
  const ItemLink = styled(NavLink)`
    display: inline-block;
    font-size: 3rem;
    font-weight: 300;
    text-decoration: none;
    color: ${COLORS.primaryLight};
    padding: 1rem 2rem;
    background-image: linear-gradient(
      120deg,
      transparent 0%,
      transparent 50%,
      #fff 50%
    );
    background-size: 240%;
    transition: all 0.4s;
    &:hover,
    &:active {
      background-position: 100%;
      color: ${COLORS.primaryDark};
      transform: translateX(1rem);
    }
  `;
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  //mobbile end

  return (
    <React.Fragment>
      {/*<!-- Start Preloader Area Set this up -->*/}
      {/*<div className="preloader-area">
                <div className="spinner">
                    <div className="inner">
                        <div className="disc"></div>
                        <div className="disc"></div>
                        <div className="disc"></div>
                    </div>
                </div>
            </div>*/}
      {/* <!-- End Preloader Area -->*/}

      {/* <!-- Start Header Area -->*/}
      <header className="main-header-area">
        {/*<!-- Start Navbar Area -->*/}
        <div className="navbar-area navbar-box-style">
          <div className="main-responsive-nav">
            <div className="container">
              <div className="main-responsive-menu">
                <div className="logo">
                  <Link to="/">
                    <img src={logo} alt="logo" style={{ height: "70px" }} />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="main-navbar">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light">
                <Link className="navbar-brand" to="/">
                  <img src={logo} alt="logo" style={{ height: "70px" }} />
                </Link>

                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav m-auto">
                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/aboutme" className="nav-link">
                        About Me
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/tutorials" className="nav-link">
                        Tutorials
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/portfolio" className="nav-link">
                        Portfolio
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/blogs" className="nav-link">
                        Blogs
                      </Link>
                    </li>
                  </ul>

                  <div className="others-options d-flex align-items-center">
                    <div className="option-item">
                      <Link to="/contactme" className="default-btn">
                        Let’s Talk <i className="ri-message-line"></i>
                        <span></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>

          <div className="others-option-for-responsive">
            <div className="container">
              {/*} <div className="dot-menu">
                                <div className="inner">
                                    <div className="circle circle-one"></div>
                                    <div className="circle circle-two"></div>
                                    <div className="circle circle-three"></div>
                                </div>
                            </div>*/}
              <div className="mobile-nav-toggler hidden-menu">
                <MenuLabel htmlFor="navi-toggle" onClick={handleClick}>
                  <Icon clicked={click}>&nbsp;</Icon>
                </MenuLabel>
                <NavBackground clicked={click}>&nbsp;</NavBackground>
              </div>

              <div className="container">
                <div className="option-inner">
                  <div className="others-options d-flex align-items-center">
                    <div className="option-item">
                      <i className="search-btn ri-search-line"></i>
                      <i className="close-btn ri-close-line"></i>
                      <div className="search-overlay search-popup">
                        <div className="search-box">
                          <form className="search-form">
                            <input
                              className="search-input"
                              name="search"
                              placeholder="Search..."
                              type="text"
                            />

                            <button className="search-button" type="submit">
                              <i className="ri-search-line"></i>
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="option-item">
                      <a href="contact.html" className="default-btn">
                        Let’s Talk <i className="ri-message-line"></i>
                        <span></span>
                      </a>
                    </div>

                    <div className="option-item">
                      <div className="side-menu-btn">
                        <i
                          className="ri-menu-4-line"
                          data-bs-toggle="modal"
                          data-bs-target="#sidebarModal"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-box">
          <Navigation clicked={click}>
            <List>
              <li>
                <ItemLink
                  onClick={handleClick}
                  to="/"
                  className="sub-menu-item"
                >
                  Home
                </ItemLink>
              </li>

              <li>
                <ItemLink
                  onClick={handleClick}
                  to="/aboutme"
                  className="sub-menu-item"
                >
                  {" "}
                  About Me
                </ItemLink>
              </li>
              <li>
                <ItemLink
                  onClick={handleClick}
                  to="/tutorials"
                  className="sub-menu-item"
                >
                  Tutorials
                </ItemLink>
              </li>
              <li>
                <ItemLink
                  onClick={handleClick}
                  to="/portfolio"
                  className="sub-menu-item"
                >
                  Portfolio
                </ItemLink>
              </li>
              <li>
                <ItemLink
                  onClick={handleClick}
                  to="/blogs"
                  className="sub-menu-item"
                >
                  Blogs
                </ItemLink>
              </li>
              <li>
                <ItemLink
                  onClick={handleClick}
                  to="/contactme"
                  className="sub-menu-item"
                >
                  Let’s Talk{" "}
                </ItemLink>
              </li>
            </List>
          </Navigation>
        </div>
        {/*<!-- End Navbar Area -->*/}
      </header>
      {/* <!-- End Header Area -->*/}
    </React.Fragment>
  );
};

export default NavBar;
