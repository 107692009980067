import http from "../general/httpService";
import api from "../../constants/config.json";

//create new tutorial comment
//create method

export function createTutorialComment(commentDetail) {
	return http.post(
		api.apiEndpoint + "/commentManagement/api/create_new_tutorial_comment",
		commentDetail,
	);
}

//create new blog comment

export function createBlogComment(commentDetail) {
	return http.post(
		api.apiEndpoint + "/commentManagement/api/create_new_blog_comment",
		commentDetail,
	);
}

//create reply of comment for tutorial

//create reply of comment for blog

//fetch all comments for target tutorial

export function fetchTutorialCommentByTutorialId(tutorialTitle) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/commentManagement/api/fetch_all_tutorial_comments_by_id/" +
		tutorialTitle;
	return http.get(fullApiEndpoint);
}

//fetch all comments for target blog
export function fetchBlogCommentByBlogId(tutorialTitle) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/commentManagement/api/find_tutorial_by_title/" +
		tutorialTitle;
	return http.get(fullApiEndpoint);
}

export function fetchTutorialCommentCount(tutorialId) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/commentManagement/api/fetch_tutorial_comment_count/" +
		tutorialId;
	return http.get(fullApiEndpoint);
}

export function fetchBlogCommentCount(blogId) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/commentManagement/api/fetch_blog_comment_count/" +
		blogId;
	return http.get(fullApiEndpoint);
}
