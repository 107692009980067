import React from "react";
import { Link } from "react-router-dom";
import courses1 from "../../../styletheme/assets/images/courses/courses-1.jpg";
import image1 from "../../../styletheme/assets/images/courses/images-1.jpg";
import dateFormat from "dateformat";
import searchnotfound from "../../../styletheme/other/illustrations/searchnotfound.png";
//import linkWrapper.css
import "../../../styletheme/other/text/linkWrapper.css";

const TutorialCard = ({
	
	authorName,
	authorImageUrl,
	tutorialPromoImageUrl,
	tutorialTitle,
	tutorialOverview,
	path,
	timeStamp,
	categories,
	onClick,
}) => {
	return (
		<React.Fragment>
			{tutorialTitle && (
				<div  className='col-lg-6 col-md-6'>
					<div className='single-courses-box'>
						<div className='courses-image'>
							<Link to={path}>
								<img
									src={tutorialPromoImageUrl}
									alt='image'
									style={{ height: "300px", width: "900px" }}
								/>
							</Link>
							<div hidden className='price'>
								free
							</div>
						</div>
						<div className='courses-content'>
							<div className='course-author d-flex align-items-center'>
								<img
									src={
										authorImageUrl != null
											? authorImageUrl
											: "https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/authorProfilePictures%2FIMG_0037.JPG?alt=media&token=5564320c-10c0-4db6-929d-cccf3dbb76e9"
									}
									className='rounded-circle'
									alt='image'
								/>
								<span>{authorName}</span>
							</div>
							<h3 
							 className="link-wrapper">
								<Link to={path}>{tutorialTitle}</Link>
							</h3>
							<p className="link-wrapper">
								{" "}
								{tutorialOverview != undefined && tutorialOverview.length > 100
									? tutorialOverview.substring(0, 100) + "..."
									: tutorialOverview}
							</p>
							<ul className='courses-box-footer d-flex justify-content-between align-items-center'>
								<li>
									<i className='ri-calendar-2-line'></i>{" "}
									{dateFormat(timeStamp, "mmmm dS, yyyy")}
								</li>
								<li>
									<i className='ri-price-tag-3-line'></i> {categories}
								</li>
							</ul>
						</div>
					</div>
				</div>
			)}
			{!tutorialTitle && (
				<section className='col-lg-8 col-md-12'>
					<Link
						to='/tutorials'
						onClick={onClick}
						className='btn btn-success d-flex justify-content-center'>
						<b>Clear Filter and Refresh Tutorials</b>
					</Link>
					<img src={searchnotfound} style={{ height: "100%", width: "100%" }} />
				</section>
			)}
		</React.Fragment>
	);
};

export default TutorialCard;
