import http from "../general/httpService";
import api from "../../constants/config.json";

//fetch_all_portfolioes
export function fetchAllBlogs() {
	console.log(`api: ${api.apiEndpoint}`);
	const fullApiEndpoint =
		api.apiEndpoint + "/blogManagement/api/fetch_all_blogs";
	return http.get(fullApiEndpoint);
}
//fetch_blog_by_id/:blogId

export function fetchBlogById(blogId) {
	const fullApiEndpoint =
		api.apiEndpoint + "/blogManagement/api/fetch_blog_by_id/" + blogId;
	return http.get(fullApiEndpoint);
}

//fetch_blog_by_title/:portfolioTitle

export function fetchBlogPieceByTitle(portfolioTitle) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/blogManagement/api/fetch_blog_by_title/" +
		portfolioTitle;
	return http.get(fullApiEndpoint);
}

//need to find the portfolio by category

//fetch_all_blog_categories
export function fetchAllBlogCategories() {
	const fullApiEndpoint =
		api.apiEndpoint + "/blogManagement/api/fetch_all_blog_categories";
	return http.get(fullApiEndpoint);
}

//fetch_latest_blog_categories/:limit
export function fetchLatestBlogCategories(limit) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/blogManagement/api/fetch_latest_blog_categories/" +
		limit;
	return http.get(fullApiEndpoint);
}

//fetch find_blog_by_categoryName
export function findBlogByCategoryName(categoryName) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/blogManagement/api/find_blog_by_categoryName/" +
		categoryName;
	return http.get(fullApiEndpoint);
}

//fetch_latest_portfolio/:limit
export function fetchLatestBlogs(limit) {
	const fullApiEndpoint =
		api.apiEndpoint + "/blogManagement/api/fetch_latest_blogs/" + limit;
	return http.get(fullApiEndpoint);
}

//fetch_latest_blog_exclude_target/:portfolioId/:limit
export function fetchLatestBlogsAndExclude(portfolioId, limit) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/blogManagement/api/fetch_latest_blog_exclude_target/" +
		portfolioId +
		"/" +
		limit;
	return http.get(fullApiEndpoint);
}

//fetch_blogs_using_pagination
export function fetchBlogsUsingPagination(page, limit) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/blogManagement/api/fetch_blogs_using_pagination/" +
		page +
		"/" +
		limit;
	return http.get(fullApiEndpoint);
}

export function fetchBlogsWithSimilarTitle(title) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/blogManagement/api/fetch_similar_blogs_by_title/" +
		title;
	return http.get(fullApiEndpoint);
}