import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  fetchLatestTutorials,
  fetchLatestTutorialCategories,
  fetchLatestTutorialByCategory,
  fetchTutorialsByPagination,
  fetchTutorialsWithSimilarTitle
} from "../../../services/tutorials/tutorialService";

import ReactPaginate from "react-paginate";

import "./../../../styletheme/other/pagination/pagination.css";
import shape1 from "../../../styletheme/assets/images/services/shape-1.png";
import shape2 from "../../../styletheme/assets/images/services/shape-2.png";
import shape3 from "../../../styletheme/assets/images/services/shape-3.png";
import shape4 from "../../../styletheme/assets/images/services/shape-4.png";
import LatestTutorialCard from "../../common/cards/latesttutorialcard";
import TutorialCard from "../../common/cards/tutorialcard";

import CategoriesGroupList from "../sidebar/categoriesGroupList";

import { toast } from "react-toastify";

import AdvertisementCompact from "../../common/advertisement/advertisementCompact";
import { getAdvertisementByWebPage } from "../../../services/advertisements/advertisementService";
import AdvertisementFullWidth from "../../common/advertisement/AdvertisementFullWidth";
import { handlePageViewStat } from "../../../services/utils/pageViewStats";



const Tutorials = (props) => {



  const isDarkMode = props.themeName === "theme-dark";
  const containerClass = isDarkMode ? "pagination-dark" : "pagination-light";
  const subContainerClass = isDarkMode ? "pages pagination-dark" : "pages pagination-light";
  const activeClass = isDarkMode ? "active-dark" : "active-light";

  //advert
  const backgroundColor = isDarkMode ? '#333': '#f7f7f7'; 
  const textColor = isDarkMode ? '#fff': '#333';
  

  const history = useNavigate();
  const { title } = useParams();
  const [isLoading, setIsLoading] = useState();
  const filtered = [];
  
  const [searchTitle, setSearchTitle] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [pageCount, setPageCount] = useState(0);

  const [tutorialsCount, setTutorialsCount] = useState(0);



  const [selectedCategory, setSelectedCategory] = useState({
    categoryId: "1",
    categoryName: "All Tutorials",
  });

  const [tutorials, setTutorials] = useState([
    {
      tutorialId: "",
      tutorialTitle: "",
      videoUploadDate: "",
      youTubeVideoCode: "",
      tutorialOverview: "",
      categoryId: "",
      tutorialPromoImage: "",
      tutorialTags: [
        {
          tagId: "",
          tagName: "",
          tagDescription: "",
        },
      ],

      commentCount: 0,
      authorName: "",
      authorProfile: {
        authorName: "",
        authorProfileImgUrl: "",
        authorProfileOverview: "",
      },
      tutorialCategories: [
        {
          categoryId: "",
          categoryName: "",
          categoryDescription: "",
        },
      ],
      tutorialSections: [
        {
          sectionId: "",
          sectionTitle: "",
          sectionSectionExplanation: "",
          sourceCodeSnippets: [
            {
              sourceCodeTitle: "",
              sourceCodeExplanation: "",
              sourceCodeDetail: "",
              languageId: "",
              languageName: "",
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: "",
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: "",
              authorProfileUrl: "",
              authorTitle: "",
            },
          ],
          referenceDetail: "",
        },
      ],
      recordCreatedDate: "",
      isDraft: false,
    },
  ]);

  const [latestTutorials, setLatestTutorials] = useState([
    {
      tutorialId: "",
      tutorialTitle: "",
      videoUploadDate: "",
      youTubeVideoCode: "",
      tutorialOverview: "",
      tutorialPromoImage: "",
      tutorialTags: [
        {
          tagId: "",
          tagName: "",
          tagDescription: "",
        },
      ],

      commentCount: 0,
      authorName: "",
      authorProfile: {
        authorName: "",
        authorProfileImgUrl: "",
        authorProfileOverview: "",
      },
      tutorialCategories: [
        {
          categoryId: "",
          categoryName: "",
          categoryDescription: "",
        },
      ],
      tutorialSections: [
        {
          sectionId: "",
          sectionTitle: "",
          sectionSectionExplanation: "",
          sourceCodeSnippets: [
            {
              sourceCodeTitle: "",
              sourceCodeExplanation: "",
              sourceCodeDetail: "",
              languageId: "",
              languageName: "",
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: "",
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: "",
              authorProfileUrl: "",
              authorTitle: "",
            },
          ],
          referenceDetail: "",
        },
      ],
      recordCreatedDate: "",
      isDraft: false,
    },
  ]);

  const [tutorialCategories, setTutorialCategories] = useState([
    {
      categoryId: "",
      categoryName: "",
      categoryDescription: "",
    },
  ]);

  const [advertisement, setAdvertisement] = useState({
    advertisementId: "",
    advertPlacement: {
      website: {
        websitePage: "",
      },
      mobileApp: {
        mobileAppPage: "",
      },
    },
    isCompactAdvertisement: false,
    advertisementImageUrl: "",
    advertisementLink: "",
    advertisementDescription: "",
    advertisementTitle: "",
    advertisementStartDate: "",
    advertisementEndDate: "",
    advertisementIsActive: false,
    recordCreatedDate: "",
  });

  const [advertisementFullWidth, setAdvertisementFullWidth] = useState({
    advertisementId: "",
    advertPlacement: {
      website: {
        websitePage: "",
      },
      mobileApp: {
        mobileAppPage: "",
      },
    },
    isCompactAdvertisement: false,
    advertisementImageUrl: "",
    advertisementLink: "",
    advertisementDescription: "",
    advertisementTitle: "",
    advertisementStartDate: "",
    advertisementEndDate: "",
    advertisementIsActive: false,
    recordCreatedDate: "",
  });


  useEffect(() => {
    //set at the top of the screen
    setIsLoading(true);

    window.scrollTo(0, 0);

    //console.log(process.env.NODE_ENV);

    if (process.env.NODE_ENV === 'production') {
       handlePageViewStat("Tutorials","","","",props.themeName,selectedCategory.categoryName,searchTitle,
       currentPage,pageSize);
    }


    getAllTutorials(currentPage,pageSize);

    getLatestTutorials();

    getTutorialCategories();

    getAdvertisements();
   
  }, [currentPage]);

  

    //advertisement
    const getAdvertisements = async () => {
      try {
    
        //Compact
        //get the advertisements from function getAdvertByWebPage(webpage,isCompactAdvertisement) 
         getAdvertisementByWebPage("tutorials","true")
         .then((response) => {
          //console.log("Advertisement");
          const result = response.data;
         
    
          if(result !== "")
          {
    
            //console.log("Compact Advertisement ");
            //console.log(result);
            setAdvertisement(result);
          }
          else
          {
            //console.log("No Compact Advertisement Found");
          }
          
        })
        .catch((error) => {
          console.log(error);
        });
    
    
        //get the fullwidth advertisement for tutorial detail page
        getAdvertisementByWebPage("tutorials","false")
        .then((response) => {
        
         const result = response.data;
        
    
         if(result !== "")
         {
    
           //console.log("Full Width Advertisement");
           //console.log(result);
           setAdvertisementFullWidth(result);
         }
         else
         {
           //console.log("No Full Width Advertisement Found");
         }
         
       })
       .catch((error) => {
         console.log(error);
       });
    
    
      } catch (error) {
        console.error("Error fetching advertisements:", error);
      }
    };


const handleNextPage = () => {
  if (currentPage < pageCount) {
    toast.success("Next Page");
    setCurrentPage((prevPage) => prevPage + 1);
  }
};

const handlePrevPage = () => {
  if (currentPage > 1) {
    toast.success("Previous Page");
    setCurrentPage(currentPage - 1);
  }
};

 const getAllTutorials = async (currentPage, pageSize) => {
  //fetch all tutorials

  fetchTutorialsByPagination(currentPage, pageSize)
  .then(response => {

    //console.log("Returned Data")
    //console.log(response.data);
    const { tutorials, pageCount } = response.data;

    //console.log(`Tutorials:  ${tutorials}`);

    setTutorials(tutorials);
    setPageCount(pageCount);
    setTutorialsCount(tutorials.length);
    setIsLoading(false);
  })
  .catch(error => {
    console.log(error);
  });
};

  const getLatestTutorials = async () => {
    //fetch latest
    const { data: latestTutorials } = await fetchLatestTutorials(5);

    if (latestTutorials !== "") setLatestTutorials(latestTutorials);
  };



  const updateAndFilterTutorialByCategory = async (value) => {
    setIsLoading(true);

    if (value !== undefined && value !== "") {
      history(`/tutorials/${value.categoryName}`);

      const { data: tutorials } = await fetchLatestTutorialByCategory(
        value.categoryName
      );
      //console.log("Count: " + tutorials.length);
      if (tutorials !== undefined && tutorials.length !== 0)
      {

        setTutorialsCount(tutorials.length);
        setTutorials(tutorials);

        window.scrollTo(0, 0);

      }
      //get if the value is all tutorials
      else if (value.categoryName === "All Tutorials") {
        setIsLoading(true);
        getAllTutorials(currentPage,pageSize);
        window.scrollTo(0, 0);
      }
      else toast.warning("No Tutorials Found");

      setIsLoading(false);
    }

    setIsLoading(false);
  };


  const getTutorialCategories = async () => {
    //fetchLatestTutorialCategories  tutorialCategories
    //tutorial categories
    const limit = 10;
    const { data: tutorialCategories } = await fetchLatestTutorialCategories(
      limit
    );
    //console.log("tutorial categories:");
    //console.log(tutorialCategories);
    if (tutorialCategories !== undefined && tutorialCategories.length > 0) {
      const categories = [
        { categoryId: "1", categoryName: "All Tutorials" },
        ...tutorialCategories,
      ];

      setTutorialCategories(categories);
    }
  };

  const updateUI = async (value) => {
    history("/tutorials");

    //set at the top of the screen
    window.scrollTo(0, 0);

    getAllTutorials(currentPage,pageSize);
  };



  const handleSearchTitle = e => {
    const { value } = e.target;
    setSearchTitle(value);
    setCurrentPage(0);
  };

  const handleSubmitSearch = () => {
    fetchTutorialsWithSimilarTitle(searchTitle)
      .then(response => {
        const tutorials = response.data;

        //clear the searchTitle
        setSearchTitle("");

        //console.log("Tutorials");
        //console.log(tutorials);

        setTutorials(tutorials);

        setTutorialsCount(tutorials.length);

        if (tutorials.length === 0) {
          getAllTutorials(currentPage,pageSize);
          
        }
      })
      .catch(e => {
        console.log(e);
      });  

  };



  return (
    <React.Fragment>
      {/*Start Preloader Area */}
      {isLoading && (
        <div className="preloader-area">
          <div className="spinner">
            <div className="inner">
              <div className="disc"></div>
              <div className="disc"></div>
              <div className="disc"></div>
            </div>
          </div>
        </div>
      )}
      {/* End Preloader Area -->*/}

      <section className="services-area pt-100 pb-70">
        {/*<!-- Start Page Banner Area -->*/}
        <div className="page-banner-area">
          <div className="container">
            <div className="page-banner-content">
              <h2>Tutorials</h2>

              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Tutorials</li>
              </ul>
            </div>
          </div>

          <div
            className="page-banner-shape-1"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape1} alt="image" />
          </div>
          <div
            className="page-banner-shape-2"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape2} alt="image" />
          </div>
          <div
            className="page-banner-shape-3"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape3} alt="image" />
          </div>
          <div
            className="page-banner-shape-4"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape4} alt="image" />
          </div>
        </div>
        {/*<!-- End Page Banner Area -->*/}

        {/*<!-- Start Courses Area -->*/}
        <div className="courses-area ptb-100">
          <div className="container">
            <div className="plod-grid-sorting row align-items-center">
              <div className="col-lg-6 col-md-6 result-count">
                <p>
                  We found <span className="count">{tutorialsCount}</span>{" "}
                  tutorials for you
                </p>
              </div>

              <div className="col-md-8">
                <div className="input-group mb-3" >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by title"
                    value={searchTitle}
                    
                    onChange={handleSearchTitle}
                  />
                  &nbsp;
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={handleSubmitSearch}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
          
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="row">
                  {tutorials?.map((value, index) => (
                    <React.Fragment  key={index}>
                    <TutorialCard
                     
                      authorName={value.authorName}
                      authorImageUrl={value.authorProfile.authorProfileImgUrl}
                      tutorialPromoImageUrl={value.tutorialPromoImage}
                      tutorialTitle={value.tutorialTitle}
                      tutorialOverview={value.tutorialOverview}
                      path={`/tutorialdetail/${value.tutorialTitle}`}
                      timeStamp={value.recordCreatedDate}
                      //   categories={value.tutorialCategories[0].categoryName}
                      onClick={() => updateUI(value)}
                    />
                    </React.Fragment>
                  ))}
                </div>
                
                <div className="col-lg-12 col-md-12">
                  {pageCount > 1 && (
                  <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(data) => {
                      const selectedPage = data.selected + 1;
                      setCurrentPage(selectedPage);
                    }}
                    containerClassName={containerClass}
                    subContainerClassName={subContainerClass}
                    activeClassName={activeClass}
                  />
                  )}
                 

                </div>

                {advertisementFullWidth.advertisementTitle && advertisementFullWidth.isCompactAdvertisement == false &&
                (
                    <AdvertisementFullWidth

                    appDescription={advertisementFullWidth.advertisementDescription}
                    appStoreLink={advertisementFullWidth.advertisementLink}
                    appName={advertisementFullWidth.advertisementTitle}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    screenshot={advertisementFullWidth.advertisementImageUrl}

                />
                )
                }
				
              </div>

              <div className="col-lg-4 col-md-12">
                <aside className="widget-area">
                  <div className="widget widget_search" hidden>
                    <form className="search-form">
                      <input
                        type="search"
                        className="search-field"
                        placeholder="Search Something"
                      />
                      <button type="submit">
                        <i className="ri-search-line"></i>
                      </button>
                    </form>
                  </div>

                  {advertisement.advertisementTitle && advertisement.isCompactAdvertisement == true &&
                    <AdvertisementCompact
                        screenshot={advertisement.advertisementImageUrl}
                        backgroundColor={backgroundColor}
                        textColor={textColor}
                        sectionHeight={'320px'}
                        appName={advertisement.advertisementTitle}
                        appDescription={advertisement.advertisementDescription}
                        appStoreLink={advertisement.advertisementLink}
                    />
                    }

                  <div className="widget widget_plod_posts_thumb">
                    <h3 className="widget-title">Latest Tutorials</h3>

                    {latestTutorials.map((value, index) => (
                      //make this into a template
                 <React.Fragment key={index}>
                      <LatestTutorialCard
                    
                        authorName={value.authorName}
                        authorImageUrl={value.authorProfile.authorProfileImgUrl}
                        tutorialPromoImageUrl={value.tutorialPromoImage}
                        tutorialTitle={value.tutorialTitle}
                        tutorialOverview={value.tutorialOverview}
                        path={`/tutorialdetail/${value.tutorialTitle}`}
                        timeStamp={value.recordCreatedDate}
                        //categories={value.tutorialCategories[0].categoryName}
                      />
                  </React.Fragment>
                    ))}
                  </div>

                  <div className="widget widget_tag_cloud">
                    <h3 className="widget-title">Categories</h3>

                 

                    <CategoriesGroupList
                        items={tutorialCategories}
                        onItemSelect={updateAndFilterTutorialByCategory}
                        selectedCategory={selectedCategory}
                      />

                  </div>
                </aside>
              </div>

              {/*Side Bar End*/}
            </div>
          </div>
        </div>
        {/*<!-- End Courses Area -->*/}
      </section>
    </React.Fragment>
  );
};

export default Tutorials;
