import React from "react";

const AdvertisementCompact = ({
  screenshot,
  backgroundColor,
  textColor,
  sectionHeight,
  appName,
  appDescription,
  appStoreLink,
}) => {
  const truncatedDescription =
    appDescription.length > 150
      ? appDescription.substring(0, 150) + "..."
      : appDescription;
  return (
    <div className="widget widget_tag_cloud" style={{ padding: "0px 0px" }}>
      <section
        className="overview-section pt-3 pb-3"
        style={{ backgroundColor: backgroundColor, color: textColor }}
      >
        <div className="container">
          <div className="row">
            <div className="col-4">
              <div className="app-screenshot">
                <img
                  src={screenshot}
                  alt="App Screenshot"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    maxHeight: "150px",
                  }}
                />
              </div>
            </div>
            <div className="col-8">
              <div className="app-info">
                <h5>{appName}</h5>
                <p>{truncatedDescription}</p>
                <a
                  href={appStoreLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary btn-sm"
                >
                  Download on App Store
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdvertisementCompact;
