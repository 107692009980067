import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import shape1 from "../../../styletheme/assets/images/services/shape-1.png";
import shape2 from "../../../styletheme/assets/images/services/shape-2.png";
import shape3 from "../../../styletheme/assets/images/services/shape-3.png";
import shape4 from "../../../styletheme/assets/images/services/shape-4.png";
import blogs1 from "../../../styletheme/assets/images/blog/blog-1.jpg";
import {
  fetchBlogsUsingPagination,
  findBlogByCategoryName,
  fetchLatestBlogCategories,
  fetchLatestBlogs,
  fetchBlogsWithSimilarTitle

} from "../../../services/blog/blogsService";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import searchnotfound from "../../../styletheme/other/illustrations/searchnotfoundblogs.png";
import BlogSideBar from "../sidebar/blogsidebar";
//new
import ReactPaginate from "react-paginate";
import { useGeolocated } from "react-geolocated";
import { getIpAddress } from "../../../services/analytics/pageVisitManager";
import { getAdvertisementByWebPage } from "../../../services/advertisements/advertisementService";
import AdvertisementCompact from "../../common/advertisement/advertisementCompact";
import AdvertisementFullWidth from "../../common/advertisement/AdvertisementFullWidth";
import { handlePageViewStat } from "../../../services/utils/pageViewStats";


const Blogs = (props) => {

  const isDarkMode = props.themeName === "theme-dark";
  const containerClass = isDarkMode ? "pagination-dark" : "pagination-light";
  const subContainerClass = isDarkMode ? "pages pagination-dark" : "pages pagination-light";
  const activeClass = isDarkMode ? "active-dark" : "active-light";

  //advert
  const backgroundColor = isDarkMode ? '#333': '#f7f7f7'; 
  const textColor = isDarkMode ? '#fff': '#333';

  //pagination and search
  const [searchTitle, setSearchTitle] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [pageCount, setPageCount] = useState(0);

  const [blogsCount, setBlogsCount] = useState(0);
 
  const history = useNavigate();
  const { categoryName } = useParams();
  const [isLoading, setIsLoading] = useState();

  const [selectedCategory, setSelectedCategory] = useState({
    categoryId: "1",
    categoryName: "All Blogs",
  });

  const [blogs, setBlogs] = useState([
    {
      blogId: "",
      blogTitle: "",
      blogImageUrl: "",
      blogOverview: "",
      blogTags: [
        {
          tagId: "",
          tagName: "",
          tagDescription: "",
        },
      ],
      commentCount: 0,
      authorName: "",
      authorProfile: {
        authorName: "",
        authorProfileImgUrl: "",
        authorProfileOverview: "",
      },
      blogCategories: [
        {
          categoryId: "",
          categoryName: "",
          categoryDescription: "",
        },
      ],
      blogSections: [
        {
          sectionId: "",
          sectionTitle: "",
          sectionExplanation: "",
          blogSectionSnippets: [
            {
              snippetTitle: "",
              snippetExplanation: "",
              snippetMedias: [
                {
                  mediaId: "",
                  mediaUrl: "",
                  mediaCaption: "",
                  mediaType: "",
                },
              ],
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: "",
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: "",
              authorProfileUrl: "",
              authorTitle: "",
            },
          ],
          referenceDetail: "",
        },
      ],
      isDraft: false,
    },
  ]);

  const [blogCategories, setBlogCategories] = useState([
    {
      categoryId: "",
      categoryName: "",
      categoryDescription: "",
    },
  ]);

 

  const [latestBlogs, setLatestBlogs] = useState([
    {
      blogId: "",
      blogTitle: "",
      blogImageUrl: "",
      blogOverview: "",
      blogTags: [
        {
          tagId: "",
          tagName: "",
          tagDescription: "",
        },
      ],
      commentCount: 0,
      authorName: "",
      authorProfile: {
        authorName: "",
        authorProfileImgUrl: "",
        authorProfileOverview: "",
      },
      blogCategories: [
        {
          categoryName: "",
          categoryDescription: "",
        },
      ],
      blogSections: [
        {
          sectionId: "",
          sectionTitle: "",
          sectionExplanation: "",
          blogSectionSnippets: [
            {
              snippetTitle: "",
              snippetExplanation: "",
              snippetMedias: [
                {
                  mediaId: "",
                  mediaUrl: "",
                  mediaCaption: "",
                  mediaType: "",
                },
              ],
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: "",
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: "",
              authorProfileUrl: "",
              authorTitle: "",
            },
          ],
          referenceDetail: "",
        },
      ],
      isDraft: false,
    },
  ]);

  const [advertisement, setAdvertisement] = useState({
    advertisementId: "",
    advertPlacement: {
      website: {
        websitePage: "",
      },
      mobileApp: {
        mobileAppPage: "",
      },
    },
    isCompactAdvertisement: false,
    advertisementImageUrl: "",
    advertisementLink: "",
    advertisementDescription: "",
    advertisementTitle: "",
    advertisementStartDate: "",
    advertisementEndDate: "",
    advertisementIsActive: false,
    recordCreatedDate: "",
  });

  const [advertisementFullWidth, setAdvertisementFullWidth] = useState({
    advertisementId: "",
    advertPlacement: {
      website: {
        websitePage: "",
      },
      mobileApp: {
        mobileAppPage: "",
      },
    },
    isCompactAdvertisement: false,
    advertisementImageUrl: "",
    advertisementLink: "",
    advertisementDescription: "",
    advertisementTitle: "",
    advertisementStartDate: "",
    advertisementEndDate: "",
    advertisementIsActive: false,
    recordCreatedDate: "",
  });


  useEffect(() => {
    //set at the top of the screen
    setIsLoading(true);

    window.scrollTo(0, 0);
   
    if (process.env.NODE_ENV === 'production') {
      handlePageViewStat("Blogs","","","",props.themeName,categoryName,searchTitle,
      currentPage,pageSize);
    }

    getBlogs();
    getBlogCategories();
    getLatestBlogs();

    getAdvertisements()

  }, [currentPage]);


  
//advertisement
const getAdvertisements = async () => {
  try {

    //Compact
    //get the advertisements from function getAdvertByWebPage(webpage,isCompactAdvertisement) 
     getAdvertisementByWebPage("blogs","true")
     .then((response) => {
     // console.log("Advertisement");
      const result = response.data;
     

      if(result !== "")
      {

        //console.log("Compact Advertisement ");
        //console.log(result);
        setAdvertisement(result);
      }
      else
      {
       // console.log("No Compact Advertisement Found");
      }
      
    })
    .catch((error) => {
      console.log(error);
    });


    //get the fullwidth advertisement for tutorial detail page
    getAdvertisementByWebPage("blogs","false")
    .then((response) => {
    
     const result = response.data;
    

     if(result !== "")
     {

       //console.log("Full Width Advertisement");
       //console.log(result);
       setAdvertisementFullWidth(result);
     }
     else
     {
      // console.log("No Full Width Advertisement Found");
     }
     
   })
   .catch((error) => {
     console.log(error);
   });


  } catch (error) {
    console.error("Error fetching advertisements:", error);
  }
};

  //fetchAllBlogs
  const getBlogs = async () => {
    if (categoryName !== undefined && categoryName !== "All Blogs") {
      const { data: blogs } = await findBlogByCategoryName(categoryName);

      if (blogs !== undefined && blogs.length !== 0) {
        setBlogs(blogs);
        setBlogsCount(blogs.length);

        setIsLoading(false);
      } else {
        toast.warning("No Blog Found");

        setIsLoading(false);
      }
    } else if (categoryName === undefined || categoryName === "All Blogs") {
      //pagination
      fetchBlogsFromServer();

    }
  };


  const fetchBlogsFromServer = async () => {
    fetchBlogsUsingPagination(currentPage, pageSize)
    .then((response) => {
      const {blogs, pageCount} = response.data;

      if (blogs !== undefined && blogs.length !== 0) {
        setBlogs(blogs);
        setBlogsCount(blogs.length);
        setPageCount(pageCount);
        setIsLoading(false);
      }
      else {
        toast.warning("No Blog Found");
        setIsLoading(false);
      }
    })
    .catch((error) => {
      toast.error(error.message);
      setIsLoading(false);
    }
    );
  };

  const getBlogCategories = async () => {
    const limit = 10;
    const { data: blogCategories } = await fetchLatestBlogCategories(limit);

    if (blogCategories !== undefined && blogCategories.length > 0){ 
      
      const categories = [  {
        categoryId: "1",
        categoryName: "All Blogs",
      }, ...blogCategories];
      
      setBlogCategories(categories);

    }
  };

  const getLatestBlogs = async () => {
    const bloglimit = 5;
    const { data: latestBlogs } = await fetchLatestBlogs(bloglimit);

    if (latestBlogs !== undefined) setLatestBlogs(latestBlogs);
  };

  const refreshPage = async () => {
    setIsLoading(true);
    history("/blogs");

    fetchBlogsFromServer();
  };

  const updateBlogsUI = async (value) => {
    setIsLoading(true);
    history("/blogs/" + value.categoryName);

    if (value.categoryName === "All Blogs") {
      fetchBlogsFromServer();
      return;
    }

    const { data: blogs } = await findBlogByCategoryName(value.categoryName);

    if (blogs !== undefined && blogs.length !== 0){ 
      
      setBlogs(blogs);
      setBlogsCount(blogs.length);

    }
    else{ 
      toast.warning("No Blogs Found, refreshing list");
      refreshPage();

  }

    setIsLoading(false);
  };
  const updateUI = async (value) => {
    setIsLoading(true);
    history("/blogs/" + value.categoryName);

    const { data: blogs } = await findBlogByCategoryName(value.categoryName);

    if (blogs !== undefined && blogs.length !== 0) setBlogs(blogs);
    else toast.warning("No Blogs Found");

    setIsLoading(false);
  };

  //handle search
  const handleSearchTitle = e => {
    const { value } = e.target;
    setSearchTitle(value);
    setCurrentPage(0);
  };

  const handleSubmitSearch = () => {
    fetchBlogsWithSimilarTitle(searchTitle)
      .then(response => {
        const blogs = response.data;

        //clear the searchTitle
        setSearchTitle("");

        //console.log("Blogs");
        //console.log(blogs);

        setBlogs(blogs);

        setBlogsCount(blogs.length);

        if (blogs.length === 0) {
          toast.warning("No Blogs Found, resetting the list");
          fetchBlogsFromServer();
          
        }
      })
      .catch(e => {
        console.log(e);
      });  

  };

  return (
    <React.Fragment>
      {/*Start Preloader Area */}
      {isLoading && (
        <div className="preloader-area">
          <div className="spinner">
            <div className="inner">
              <div className="disc"></div>
              <div className="disc"></div>
              <div className="disc"></div>
            </div>
          </div>
        </div>
      )}
      {/* End Preloader Area -->*/}

      <section className="services-area pt-100 pb-70">
        {/*<!-- Start Page Banner Area -->*/}
        <div className="page-banner-area">
          <div className="container">
            <div className="page-banner-content">
              <h2>Blogs</h2>

              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Blogs</li>
              </ul>
            </div>
          </div>

          <div
            className="page-banner-shape-1"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape1} alt="image" />
          </div>
          <div
            className="page-banner-shape-2"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape2} alt="image" />
          </div>
          <div
            className="page-banner-shape-3"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape3} alt="image" />
          </div>
          <div
            className="page-banner-shape-4"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape4} alt="image" />
          </div>
        </div>
        {/*<!-- End Page Banner Area -->*/}

        {/*<!-- Start Blog Area -->*/}
        <div className="blog-area pt-100 pb-100">
          <div className="container">

          <div className="col-md-8">
                <div className="input-group mb-3" >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by title"
                    value={searchTitle}
                    
                    onChange={handleSearchTitle}
                  />
                  &nbsp;
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={handleSubmitSearch}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>

            <div className="row">
              <div className="col-lg-8 col-md-8">
                {blogs.length > 0 && (
                  <div className="row">
                    {blogs.map((value, index) => (
            
                      <React.Fragment key={index}>
                                  <div  className='col-md-6'>
                        {value.blogImageUrl && (
                          <div className="single-blog">
                            <div className="blog-image" style={{ backgroundColor: '#f2f2f2', overflow: 'hidden' }}>
                              <Link to={`/blogdetail/${value.blogTitle}`}>
                                <img src={value.blogImageUrl} alt="image" />
                              </Link>
                            </div>

                            <div className="blog-content">
                              <ul className="entry-meta">
                                <li className="tag">
                                  {value.blogCategories &&
                                    value.blogCategories.length > 0 && (
                                      <span>
                                        {value.blogCategories[0].categoryName}
                                      </span>
                                    )}
                                </li>
                                <li>
                                  <i className="ri-time-line"></i>
                                  {dateFormat(
                                    value.recordCreatedDate,
                                    "mmmm dS, yyyy"
                                  )}
                                </li>
                                <li>
                                  <i className="ri-message-2-line"></i>
                                  (0) Comment
                                </li>
                              </ul>
                              <h3>
                                <Link to={`/blogdetail/${value.blogTitle}`}>
                                  {value.blogTitle}
                                </Link>
                              </h3>
                              <Link
                                to={`/blogdetail/${value.blogTitle}`}
                                className="blog-btn"
                              >
                                Read More{" "}
                                <i className="ri-arrow-right-line"></i>
                              </Link>
                            </div>
                          </div>
                        )}
                        {!value.blogImageUrl && (
                          <section>
                            <Link
                              to="/blogs"
                              onClick={() => refreshPage()}
                              className="btn btn-success d-flex justify-content-center"
                            >
                              <b>Clear Filter and Refresh Blogs</b>
                            </Link>
                            <img
                              src={searchnotfound}
                              style={{ height: "100%", width: "100%" }}
                            />
                          </section>
                        )}
                      </div>

                      
                      </React.Fragment>
                    ))}

                <div className="col-lg-12 col-md-12">
                  {pageCount > 1 && (
                    <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={(data) => {
                        const selectedPage = data.selected + 1;
                        setCurrentPage(selectedPage);
                      }}
                      containerClassName={containerClass}
                      subContainerClassName={subContainerClass}
                      activeClassName={activeClass}
                    />
                  )}
                 

                </div>

                {advertisementFullWidth.advertisementTitle && advertisementFullWidth.isCompactAdvertisement == false &&
                (
                    <AdvertisementFullWidth

                    appDescription={advertisementFullWidth.advertisementDescription}
                    appStoreLink={advertisementFullWidth.advertisementLink}
                    appName={advertisementFullWidth.advertisementTitle}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    screenshot={advertisementFullWidth.advertisementImageUrl}

                />
                )
                }


                  </div>
                )}
              </div>

              <div className="col-lg-4 col-md-12">
                {/* Side bar start*/}
                <aside className="widget-area">
                  <div className="widget widget_search" hidden>
                    <form className="search-form">
                      <input
                        type="search"
                        className="search-field"
                        placeholder="Search Something"
                      />
                      <button type="submit">
                        <i className="ri-search-line"></i>
                      </button>
                    </form>
                  </div>

                  {advertisement.advertisementTitle && advertisement.isCompactAdvertisement == true &&
                    <AdvertisementCompact
                        screenshot={advertisement.advertisementImageUrl}
                        backgroundColor={backgroundColor}
                        textColor={textColor}
                        sectionHeight={'320px'}
                        appName={advertisement.advertisementTitle}
                        appDescription={advertisement.advertisementDescription}
                        appStoreLink={advertisement.advertisementLink}
                    />
                  }

                  <div className="widget widget_tag_cloud">
                    <h3 className="widget-title">Blog Categories</h3>

                    <div className="tagcloud">
                      {blogCategories.map((value, index) => (
                        <span key={index}>
                          <Link
                          className={value === selectedCategory ? "current" : ""}
                            onClick={() => updateBlogsUI(value)}
                            key={index}
                            to={`/blogs/${value.categoryName}`}
                          >
                            {value.categoryName}
                          </Link>
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="widget widget_plod_posts_thumb">
                    <h3 className="widget-title">Recent Blogs</h3>

                    {latestBlogs.map((value, index) => (
                      <article key={index} className="item">
                        <Link
                          onClick={() => updateUI(value)}
                          to={`/blogdetail/${value.blogTitle}`}
                          className="thumb"
                        >
                          <img
                            src={value.blogImageUrl}
                            className="fullimage cover bg1"
                            role="img"
                          />
                        </Link>

                        <div className="info">
                          <h4 className="title usmall">
                            <Link
                              onClick={() => updateUI(value)}
                              to={`/blogdetail/${value.blogTitle}`}
                            >
                              {value.blogTitle}
                            </Link>
                          </h4>

                          <span>
                            <i className="ri-time-line"></i>{" "}
                            {dateFormat(
                              value.recordCreatedDate,
                              "mmmm dS, yyyy"
                            )}
                          </span>
                        </div>
                      </article>
                    ))}
                  </div>

                  <div className="widget widget_tag_cloud" hidden>
                    <h3 className="widget-title">Popular Tags</h3>

                    <div className="tagcloud">
                      <a href="#">Agency</a>
                      <a href="#">Branding</a>
                      <a href="#">Marketing</a>
                      <a href="#">Design</a>
                      <a href="#">Development</a>
                      <a href="#">Consulting</a>
                      <a href="#">Startup</a>
                      <a href="#">Popular</a>
                      <a href="#">WordPress</a>
                      <a href="#">Financial</a>
                      <a href="#">Branding</a>
                    </div>
                  </div>
                </aside>

                {/*Side bar end */}
              </div>
            </div>
          </div>

          <div className="blog-shape-1" data-speed="0.08" data-revert="true">
            <img src={shape1} alt="image" />
          </div>
          <div className="blog-shape-2" data-speed="0.08" data-revert="true">
            <img src={shape2} alt="image" />
          </div>
        </div>
        {/*<!-- End Blog Area -->*/}
      </section>
    </React.Fragment>
  );
};

export default Blogs;
