import http from "../general/httpService";
import api from "../../constants/config.json";

//create method
export function createNewContactRequest(requestDetail) {
	return http.post(
		api.apiEndpoint +
			"/contactRequestManagement/api/create_new_contact_request",
		requestDetail,
	);
}
