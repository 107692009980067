import React, { Component, useEffect, useState } from "react";
import shape1 from "../../../styletheme/assets/images/services/shape-1.png";
import shape2 from "../../../styletheme/assets/images/services/shape-2.png";
import shape3 from "../../../styletheme/assets/images/services/shape-3.png";
import shape4 from "../../../styletheme/assets/images/services/shape-4.png";
import shape5 from "../../../styletheme/assets/images/services/shape-5.png";
import tagicon from "../../../styletheme/assets/images/about/tag-icon.png";
import { Link } from "react-router-dom";
import about from "../../../styletheme/assets/images/about/about.png";
import img1 from "../../../styletheme/assets/images/about/img1.png";
import img2 from "../../../styletheme/assets/images/about/img2.png";
import img3 from "../../../styletheme/assets/images/about/img3.png";
import { fetchAboutMe } from "../../../services/about/aboutService";
import { handlePageViewStat } from "../../../services/utils/pageViewStats";

const AboutMe = () => {
  const [isLoading, setIsLoading] = useState();

  const [aboutMe, setAboutMe] = useState({
    aboutId: "",
    heroImageUrl: "",
    aboutAuthorName: "",
    aboutOverview: "",
    technologiesKnown: [
      {
        technologyName: "",
        technologyImageUrl: "",
        technologyDescription: "",
        isTechTopThree: false,
      },
    ],
    recordCreatedDate: "",
  });

  useEffect(() => {
    //set at the top of the screen
    window.scrollTo(0, 0);


    if (process.env.NODE_ENV === 'production') {
      handlePageViewStat("AboutMe","","","","N/A","","",
      0,0);
   }

    getProfile();
  }, []);

  const getProfile = async () => {
    setIsLoading(true);
    //promise will complete in the future or in pending then resolved (success) or rejected if fail
    //wait till the promised results appears then extract the values
    //get data and rename it to courses (the object name)
    //get all discussion by the module id
    const { data: aboutMe } = await fetchAboutMe();
    //const result = Object.entries(discussions);
    //console.log("aboutMe:");
    //console.log(aboutMe);
    //update the courses by setting the state
    if (aboutMe.aboutId !== "") {
      setAboutMe(aboutMe);



      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/*Start Preloader Area */}
      {isLoading && (
        <div className="preloader-area">
          <div className="spinner">
            <div className="inner">
              <div className="disc"></div>
              <div className="disc"></div>
              <div className="disc"></div>
            </div>
          </div>
        </div>
      )}
      {/* End Preloader Area -->*/}

      <section className="services-area pt-100 pb-70">
        {/*<!-- Start Page Banner Area -->*/}
        <div className="page-banner-area">
          <div className="container">
            <div className="page-banner-content">
              <h2>About Me</h2>

              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>About Me</li>
              </ul>
            </div>
          </div>

          <div
            className="page-banner-shape-1"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape1} alt="image" />
          </div>
          <div
            className="page-banner-shape-2"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape2} alt="image" />
          </div>
          <div
            className="page-banner-shape-3"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape4} alt="image" />
          </div>
          <div
            className="page-banner-shape-4"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape4} alt="image" />
          </div>
        </div>
        {/*<!-- End Page Banner Area -->*/}

        {/*} <!-- Start About Area -->*/}
        <div className="about-area">
          <div className="container">
            <div className="row align-items-flex-start">
              <div className="col-lg-6">
                <div
                  className="about-image"
                  data-speed="0.02"
                  data-revert="true"
                >
                  <img
                    //style={{}}
                    src={aboutMe.heroImageUrl}
                    style={{ height: "100%", width: "100%" }}
                    alt="image"
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="about-item">
                  <div className="about-content">
                    <div className="tag">
                      <img src={tagicon} alt="image" />
                    </div>
                    <h3>Hi, I am {aboutMe.aboutAuthorName}</h3>
                    <p>{aboutMe.aboutOverview}</p>
                  </div>
                  <br />
                  {/* On click it filters on the tutorials to show tutorials related to this knowledge area*/}
                  <h3>Top Knowledge Areas</h3>
                  {aboutMe.technologiesKnown
                    .filter((tech) => tech.isTechTopThree == true)
                    .map((value, index) => (
                      <div key={index} className="about-inner-content">
                        <img
                          src={value.technologyImageUrl}
                          alt="image"
                          style={{ width: "60px", height: "60px" }}
                        />
                        <h4>{value.technologyName}</h4>
                        <p>{value.technologyDescription}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="about-shape-1" data-speed="0.08" data-revert="true">
            <img src={shape1} alt="image" />
          </div>
          <div className="about-shape-2" data-speed="0.08" data-revert="true">
            <img src={shape2} alt="image" />
          </div>
          <div className="about-shape-3" data-speed="0.08" data-revert="true">
            <img src={shape3} alt="image" />
          </div>
        </div>
        {/* <!-- End About Area -->*/}
        {/*<!-- Start Services Area -->*/}

        <div className="services-area bg-without-box-color pt-100 pb-70">
          <div className="page-banner-content">
            <h3>All Significant Knowledge Areas</h3>
            <br />
          </div>
          <div className="container">
            <div className="row justify-content-center">
              {aboutMe.technologiesKnown.map((value, index) => (
                <div key={index} className="col-lg-4 col-md-6">
                  <div className="single-services-box">
                    <div className="icon">
                      <img
                        src={value.technologyImageUrl}
                        alt="image"
                        style={{ width: "60px", height: "60px" }}
                      />
                    </div>
                    <h3>
                      <Link to={`/tutorials/${value.technologyName}`}>
                        {value.technologyName}
                      </Link>
                    </h3>
                    <p>{value.technologyDescription}</p>
                    <Link
                      to={`/tutorials/${value.technologyName}`}
                      className="services-btn"
                    >
                      Read Tutorials <i className="ri-arrow-right-line"></i>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            className="services-shape-1"
            data-speed="0.06"
            data-revert="true"
          >
            <img src={shape1} alt="image" />
          </div>
          <div
            className="services-shape-2"
            data-speed="0.06"
            data-revert="true"
          >
            <img src={shape2} alt="image" />
          </div>
          <div
            className="services-shape-3"
            data-speed="0.06"
            data-revert="true"
          >
            <img src={shape3} alt="image" />
          </div>
          <div
            className="services-shape-4"
            data-speed="0.06"
            data-revert="true"
          >
            <img src={shape4} alt="image" />
          </div>
        </div>
        {/*<!-- End Services Area -->*/}
      </section>
    </React.Fragment>
  );
};

export default AboutMe;
