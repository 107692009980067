import { v4 as uuidv4 } from 'uuid';
import { BrowserView, isDesktop, isTablet, isMobile,isWindows,isMacOs,isIOS,isAndroid  } from 'react-device-detect';
import { createNewPageViewStat, getIpAddress } from '../analytics/pageVisitManager';

export const handlePageViewStat = async (
  page,
  pageViewStatArticleId,
  pageViewStatTutorialId,
  pageViewStatPortfolioId,
  pageTheme,
  selectedCategory,
  searchTitle,
  currentPage, 
  pageSize
  ) => {
  //get the ip address
  const ipAddressResponse = await getIpAddress();
  const ipAddress = ipAddressResponse ? ipAddressResponse.data : null;

  //console.log("ipAddress: " + ipAddress.ip);
  const ipAddressFound = ipAddress.ip;

  //get the browser
  //console.log('Browser:', navigator.userAgent);
  const browser = navigator.userAgent;

  //get the time spent on the page
  const dateTimeVisited = Date.now();

  //get the isUnique
  const isUnique = false;

  //get the device type
  const deviceType = isDesktop ? 'Desktop' : isTablet ? 'Tablet' : isMobile ? 'Mobile' : 'Unknown';

  const os = isWindows ? 'Windows' : isMacOs ? 'macOS' : isIOS ? 'iOS' : isAndroid ? 'Android' : '';

  //console.log("Device Type: " + deviceType);
  //console.log("OS: " + os);

  //create the object
  const pageViewStat = {
    pageViewStatId: uuidv4(),
    pageViewStatDate: dateTimeVisited,
    pageViewStatIpAddress: ipAddressFound,
    pageViewStatBrowser: browser,
    pageViewStatPage: page,
    pageViewStatTimeSpent: 0,
    pageViewStatIsUnique: isUnique,
    recordCreatedDate: dateTimeVisited,
    operatingSystem: os,
    deviceType: deviceType,
    pageViewStatArticleId: pageViewStatArticleId,
    pageViewStatTutorialId: pageViewStatTutorialId,
    pageViewStatUserId: uuidv4(), 
    pageViewStatPortfolioId: pageViewStatPortfolioId,//new
    pageTheme: pageTheme,
    selectedCategory: selectedCategory,
    searchTitle: searchTitle,
    currentPage: currentPage,
    pageSize: pageSize,
  };

  //createNewPageViewStat
  //save the object
try {
  await createNewPageViewStat(pageViewStat);
  // handle successful response here
} catch (error) {
  // handle error here
  if (process.env.NODE_ENV !== 'production') {
    console.error('stat error:', error);
  }
}
};