import http from "../general/httpService";
import api from "../../constants/config.json";

//fetch
export function fetchAllTutorials() {
	const fullApiEndpoint =
		api.apiEndpoint + "/tutorialManagement/api/fetch_all_tutorials";
	return http.get(fullApiEndpoint);
}
//fetch all draft
export function fetchTutorialByTitle(tutorialTitle) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/tutorialManagement/api/find_tutorial_by_title/" +
		tutorialTitle;
	return http.get(fullApiEndpoint);
}

export function fetchTutorialsWithSimilarTitle(tutorialTitle) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/tutorialManagement/api/find_tutorials_with_similar_title/" +
		tutorialTitle;
	return http.get(fullApiEndpoint);
}

//fetch latest 5 performances
export function fetchLatestTutorials(limit) {
	//fetch_lastest_video_performances

	const fullApiEndpoint =
		api.apiEndpoint + "/tutorialManagement/api/fetch_latest_tutorials/" + limit;
	return http.get(fullApiEndpoint);
}

export function fetchAllTutorialCategories() {
	const fullApiEndpoint =
		api.apiEndpoint + "/tutorialCategoryManagement/api/fetch_all_categories";
	return http.get(fullApiEndpoint);
}

//
export function fetchLatestTutorialCategories(limit) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/tutorialCategoryManagement/api/fetch_latest_categories/" +
		limit;
	return http.get(fullApiEndpoint);
}
//

export function fetchLatestTutorialByCategory(categoryName) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/tutorialManagement/api/find_tutorial_by_categoryName/" +
		categoryName;
	return http.get(fullApiEndpoint);
}


///api/fetch_tutorials_by_page/:page/:limit
//this url is used to fetch tutorials by page using pagination
export function fetchTutorialsByPagination(page, limit) {

	//console.log(`page: ${page} limit: ${limit}`);

	const fullApiEndpoint =
		api.apiEndpoint +
		"/tutorialManagement/api/fetch_tutorials_by_page/" +
		page +
		"/" +
		limit;
	return http.get(fullApiEndpoint);
}

