import React, { Component, useEffect, useState } from "react";
import "../../../styletheme/other/portfolio/portfolioDetail.css";
import shape1 from "../../../styletheme/assets/images/services/shape-1.png";
import shape2 from "../../../styletheme/assets/images/services/shape-2.png";
import shape3 from "../../../styletheme/assets/images/services/shape-3.png";
import shape4 from "../../../styletheme/assets/images/services/shape-4.png";
import shape5 from "../../../styletheme/assets/images/services/shape-5.png";
import case1 from "../../../styletheme/assets/images/cases-details/cases-details-1.jpg";
import case2 from "../../../styletheme/assets/images/cases-details/cases-details-2.jpg";
import case3 from "../../../styletheme/assets/images/cases-details/cases-details-3.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
//import withRouter from "../../common/workaround/withRouter";
import {
  fetchPortfolioPieceByTitle,
  fetchLatestPortfolios,
  fetchLatestPortfoliosAndExclude,
} from "../../../services/portfolio/portfolioService";
import { toast } from "react-toastify";
import searchnotfound from "../../../styletheme/other/illustrations/searchnotfoundportfolio.png";
import Iframe from "react-iframe";
import dateFormat from "dateformat";
import { getAdvertisementByWebPage } from "../../../services/advertisements/advertisementService";
import AdvertisementCompact from "../../common/advertisement/advertisementCompact";
import AdvertisementFullWidth from "../../common/advertisement/AdvertisementFullWidth";
import { handlePageViewStat } from "../../../services/utils/pageViewStats";
//new



//TODO next then update the mobile nav to look like the med-a own
const PortfolioDetail = (props) => {
  const [isLoading, setIsLoading] = useState();
  const history = useNavigate();
  const { portfolioTitle } = useParams();

const isDarkMode = props.themeName === "theme-dark";
  //advert
const backgroundColor = isDarkMode ? '#333': '#f7f7f7'; 
const textColor = isDarkMode ? '#fff': '#333';

  const [portfolio, setPortfolio] = useState({
    portfolioId: "",
    portfolioTitle: "",
    portfolioImageUrl: "",
    portfolioOverview: "",
    portfolioBackground: "",
    portfolioTags: [
      {
        tagId: "",
        tagName: "",
        tagDescription: "",
      },
    ],
    portfolioTechnologies: [
      {
        technologyId: "",
        technologyName: "",
        technologyDescription: "",
      },
    ],
    commentCount: 0,
    authorName: "",
    authorProfile: {
      authorName: "",
      authorProfileImgUrl: "",
      authorProfileOverview: "",
    },
    portfolioCategories: [
      {
        categoryName: "",
        categoryDescription: "",
      },
    ],
    portfolioSectionSnippets: [
      {
        snippetTitle: "",
        snippetExplanation: "",
        snippetMedias: [
          {
            mediaId: "",
            mediaUrl: "",
            mediaCaption: "",
            mediaType: "",
          },
        ],
      },
    ],
    portfolioHighlightedFeatureSection: [
      {
        sectionId: "",
        sectionTitle: "",
        sectionExplanation: "",
        portfolioSectionSnippets: [
          {
            snippetTitle: "",
            snippetExplanation: "",
            snippetMedias: [
              {
                mediaId: "",
                mediaUrl: "",
                mediaCaption: "",
                mediaType: "",
              },
            ],
          },
        ],
      },
    ],
    references: [
      {
        referenceTitle: "",
        referenceYear: 0,
        referenceAuthors: [
          {
            authorName: "",
            authorProfileUrl: "",
            authorTitle: "",
          },
        ],
        referenceDetail: "",
      },
    ],
    marketingInfo: {
      mediaType: "",
      mediaUrl: "",
      webLink: "",
      androidLink: "",
      appleLink: "",
      youtubeChannelLink: "",
      facebookPageLink: "",
      twitterProfileLink: "",
    },
    recordCreatedDate: "",
    isDraft: false,
  });

  const [latestPortfolios, setLatestPortfolios] = useState([
    {
      portfolioId: "",
      portfolioTitle: "",
      portfolioImageUrl: "",
      portfolioOverview: "",
      portfolioBackground: "",
      portfolioTags: [
        {
          tagId: "",
          tagName: "",
          tagDescription: "",
        },
      ],
      portfolioTechnologies: [
        {
          technologyId: "",
          technologyName: "",
          technologyDescription: "",
        },
      ],
      commentCount: 0,
      authorName: "",
      authorProfile: {
        authorName: "",
        authorProfileImgUrl: "",
        authorProfileOverview: "",
      },
      portfolioCategories: [
        {
          categoryName: "",
          categoryDescription: "",
        },
      ],
      portfolioSectionSnippets: [
        {
          snippetTitle: "",
          snippetExplanation: "",
          snippetMedias: [
            {
              mediaId: "",
              mediaUrl: "",
              mediaCaption: "",
              mediaType: "",
            },
          ],
        },
      ],
      portfolioHighlightedFeatureSection: [
        {
          sectionId: "",
          sectionTitle: "",
          sectionExplanation: "",
          portfolioSectionSnippets: [
            {
              snippetTitle: "",
              snippetExplanation: "",
              snippetMedias: [
                {
                  mediaId: "",
                  mediaUrl: "",
                  mediaCaption: "",
                  mediaType: "",
                },
              ],
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: "",
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: "",
              authorProfileUrl: "",
              authorTitle: "",
            },
          ],
          referenceDetail: "",
        },
      ],
      marketingInfo: {
        mediaType: "",
        mediaUrl: "",
        webLink: "",
        androidLink: "",
        appleLink: "",
        youtubeChannelLink: "",
        facebookPageLink: "",
        twitterProfileLink: "",
      },
      recordCreatedDate: "",
      isDraft: false,
    },
  ]);


  const [advertisement, setAdvertisement] = useState({
    advertisementId: "",
    advertPlacement: {
      website: {
        websitePage: "",
      },
      mobileApp: {
        mobileAppPage: "",
      },
    },
    isCompactAdvertisement: false,
    advertisementImageUrl: "",
    advertisementLink: "",
    advertisementDescription: "",
    advertisementTitle: "",
    advertisementStartDate: "",
    advertisementEndDate: "",
    advertisementIsActive: false,
    recordCreatedDate: "",
  });

  const [advertisementFullWidth, setAdvertisementFullWidth] = useState({
    advertisementId: "",
    advertPlacement: {
      website: {
        websitePage: "",
      },
      mobileApp: {
        mobileAppPage: "",
      },
    },
    isCompactAdvertisement: false,
    advertisementImageUrl: "",
    advertisementLink: "",
    advertisementDescription: "",
    advertisementTitle: "",
    advertisementStartDate: "",
    advertisementEndDate: "",
    advertisementIsActive: false,
    recordCreatedDate: "",
  });

  useEffect(() => {
    //set at the top of the screen
    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      handlePageViewStat("PortfolioDetail","","",portfolioTitle,props.themeName,"","",
      0,0);
    }

    getPortfolioAndLastestPortfolioPieces();

    getAdvertisements();
  }, []);



  //advertisement
  const getAdvertisements = async () => {
    try {
  
      //Compact
      //get the advertisements from function getAdvertByWebPage(webpage,isCompactAdvertisement) 
       getAdvertisementByWebPage("portfoliodetail","true")
       .then((response) => {
        //console.log("Advertisement");
        const result = response.data;
       
  
        if(result !== "")
        {
  
          //console.log("Compact Advertisement ");
          //console.log(result);
          setAdvertisement(result);
        }
        else
        {
          //console.log("No Compact Advertisement Found");
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
  
  
      //get the fullwidth advertisement for tutorial detail page
      getAdvertisementByWebPage("portfoliodetail","false")
      .then((response) => {
      
       const result = response.data;
      
  
       if(result !== "")
       {
  
         //console.log("Full Width Advertisement");
         //console.log(result);
         setAdvertisementFullWidth(result);
       }
       else
       {
         //console.log("No Full Width Advertisement Found");
       }
       
     })
     .catch((error) => {
       console.log(error);
     });
  
  
    } catch (error) {
      console.log("Error fetching advertisements:", error);
    }
  };


  const getPortfolioAndLastestPortfolioPieces = async () => {
    setIsLoading(true);

    //if portfolioTitle found then filter, if not fetch all

    if (portfolioTitle !== undefined) {
      //console.log("Search by " + portfolioTitle);
      const { data: portfolio } = await fetchPortfolioPieceByTitle(
        portfolioTitle
      );
      //console.log(portfolio);
      //console.log("Title: " + portfolio.portfolioTitle);
      if (portfolio !== undefined && portfolio.length !== 0) {
        setPortfolio(portfolio);
        const limit = 3;

        const { data: latestPortfolios } =
          await fetchLatestPortfoliosAndExclude(portfolio.portfolioId, limit);

        if (latestPortfolios !== undefined) {
          setLatestPortfolios(latestPortfolios);
          setIsLoading(false);
        }
        //console.log("Latest Other");
        //console.log(latestPortfolios);
      } else
        toast.warning(
          "No Portfolio Item Found, Please select Portfolio To see all available."
        );
      setIsLoading(false);
    } else if (portfolioTitle === undefined) {
      toast.warning(
        "No Portfolio Item Found, Please select Portfolio To see all available."
      );
      setIsLoading(false);
    }

    //fetchLatestPortfolios,fetchLatestPortfoliosAndExclude
  };
  const updateUI = async (value) => {
    setIsLoading(true);
    //set at the top of the screen
    window.scrollTo(0, 0);
    //() =>  makes sure it does trigger the method until clicked
    if (value !== undefined && value !== "") {
      //work around
      history(`/portfoliodetail/${value.portfolioTitle}`, {
        replace: true,
      });

      const { data: portfolio } = await fetchPortfolioPieceByTitle(
        value.portfolioTitle
      );

      if (portfolio !== undefined && portfolio.length !== 0) {
        setPortfolio(portfolio);
        setIsLoading(false);
      } else {
        toast.warning("No Portfolio Item Found");
      }
      //get latest again
      const limit = 3;
      const { data: latestPortfolios } = await fetchLatestPortfoliosAndExclude(
        portfolio.value,
        limit
      );

      if (latestPortfolios !== undefined) {
        setLatestPortfolios(latestPortfolios);
        setIsLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      {/*Start Preloader Area */}
      {isLoading && (
        <div className="preloader-area">
          <div className="spinner">
            <div className="inner">
              <div className="disc"></div>
              <div className="disc"></div>
              <div className="disc"></div>
            </div>
          </div>
        </div>
      )}
      {/* End Preloader Area -->*/}

      <section className="services-area pt-100 pb-70">
        {/*<!-- Start Page Banner Area -->*/}
        <div className="page-banner-area">
          <div className="container">
            <div className="page-banner-content">
              <h2>{portfolio.portfolioTitle}</h2>

              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Portfolio Detail</li>
              </ul>
            </div>
          </div>
        
          <div
            className="page-banner-shape-1"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape1} alt="image" />
          </div>
          <div
            className="page-banner-shape-2"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape2} alt="image" />
          </div>
          <div
            className="page-banner-shape-3"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape3} alt="image" />
          </div>
          <div
            className="page-banner-shape-4"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape4} alt="image" />
          </div>
        </div>
        {/*<!-- End Page Banner Area -->*/}

        {/*<!-- Start Case Study Details Area -->*/}

        {!portfolio.portfolioTitle && ( //!portfolioTitle && portfolioTitle !== undefined && itemCount === 0 &&
          <section className="col-lg-8 col-md-12">
            <Link
              to="/portfolio"
              className="btn btn-success d-flex justify-content-center"
            >
              <b>Clear Filter and Refresh Portfolio</b>
            </Link>
            <img
              src={searchnotfound}
              style={{ height: "100%", width: "100%" }}
            />
          </section>
        )}
        {portfolio.portfolioTitle && (
          <div className="case-details-area ptb-100">
            <div className="container">
              <p>{portfolio.portfolioOverview}</p>
              <div className="case-details-image" style={{ backgroundColor: '#f2f2f2', overflow: 'hidden' }}>
                <img
                  src={portfolio.portfolioImageUrl}
                  alt="image"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "400px",
                    width: "auto",
                    height: "auto",
                    objectFit: "contain",
                    display: "block",
                  }}
                />
              </div>

              <div className="row">
                <div className="col-lg-9 col-md-12">
                  {portfolio.portfolioSectionSnippets.map((value, index) => (
                    <section key={index}>
                      <div key={index} className="case-details-content">
                        <h4>{value.snippetTitle}</h4>
                        <p>{value.snippetExplanation}</p>

                        <div className="row">
                          {" "}
                          {/* Add this row div */}
                          {value.snippetMedias.map((value, index) => (
                            <div className="col-lg-6 col-md-12">
                              {" "}
                              {/* Update the column classes */}
                              <div
                                key={index}
                                className="single-services-box"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                               backgroundColor: '#f2f2f2', overflow: 'hidden' }}
                              >
                                {value.mediaType === "Image" && (
                                  <React.Fragment>
                                    <img
                                      src={value.mediaUrl}
                                      alt="image"
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "400px",
                                        width: "auto",
                                        height: "auto",
                                        objectFit: "contain",
                                        display: "block",
                                      }}
                                    />
                                    <br />
                                    <b>
                                      <i>
                                        <p
                                          style={{
                                            fontSize: "12px",
                                            textAlign: "center",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {value.mediaCaption}
                                        </p>
                                      </i>
                                    </b>
                                    <br />
                                  </React.Fragment>
                                )}
                                {value.mediaType === "Video" && (
                                  <React.Fragment>
                                    <Iframe
                                      url={
                                        "https://www.youtube.com/embed/" +
                                        value.mediaUrl
                                      }
                                      width="100%"
                                      height="500px"
                                      id="youTubeVideoCode"
                                      className="rounded-top"
                                      display="initial"
                                      frameborder="0"
                                      allowfullscreen
                                      position="relative"
                                    />
                                    <br />
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </section>
                  ))}
                </div>

                <div className="col-lg-3 col-md-12">
                  <div className="case-details-information">
                    <ul className="information-list">
                      <li>
                        <span>Share On :</span>
                        {portfolio.marketingInfo.facebookPageLink && (
                          <a
                            href={
                                portfolio.marketingInfo.facebookPageLink
                            }
                            target="_blank"
                          >
                            <i className="ri-facebook-fill"></i>
                          </a>
                        )}
                        {portfolio.marketingInfo.twitterProfileLink && (
                          <a
                            href={
                                portfolio.marketingInfo.twitterProfileLink
                            }
                            target="_blank"
                          >
                            <i className="ri-twitter-fill"></i>
                          </a>
                        )}
                        {portfolio.marketingInfo.youtubeChannelLink && (
                          <a
                            href={
                                portfolio.marketingInfo.youtubeChannelLink}
                            target="_blank"
                          >
                            <i className="ri-youtube-fill"></i>
                          </a>
                        )}
                      </li>

                      <li>
                        <span>Category :</span>{" "}
                        {portfolio.portfolioCategories.map((value, index) => (
                          <span key={index}>
                            {" "}
                            {value.categoryName}
                            {index <
                              portfolio.portfolioCategories.length - 1 && (
                              <span>, </span>
                            )}
                          </span>
                        ))}
                      </li>

                      <li>
                        <span>Created By :</span> {portfolio.authorName}
                      </li>
                      {portfolio.marketingInfo.webLink && (
                        <li>
                          <span>Website :</span>{" "}
                          <a
                            href={ portfolio.marketingInfo.webLink }
                            target="_blank"
                          >
                            {portfolio.marketingInfo.webLink}
                          </a>
                        </li>
                      )}
                      <li>
                        <span>Last Updated On :</span>{" "}
                        {dateFormat(
                          portfolio.recordCreatedDate,
                          "mmmm dS, yyyy"
                        )}
                      </li>
                      <li>
                        <span>Tags :</span>{" "}
                        {portfolio.portfolioTags.map((value, index) => (
                          <span key={index}>
                            {" "}
                            {value.tagName}
                            {index < portfolio.portfolioTags.length - 1 && (
                              <span>, </span>
                            )}
                          </span>
                        ))}
                      </li>

                      <li>
                        <span>Technology :</span>{" "}
                        {portfolio.portfolioTechnologies.map((value, index) => (
                          <span key={index}>
                            {" "}
                            {value.technologyName}
                            {index <
                              portfolio.portfolioTechnologies.length - 1 && (
                              <span>, </span>
                            )}
                          </span>
                        ))}
                      </li>
                    </ul>
                    {/*Google Play Apple etc  */}

                 

                    {(portfolio.marketingInfo.appleLink !== "" ||
                      portfolio.marketingInfo.androidLink !== "") && (
                      <div className="case-contact-info">
                        <h3 className="found-on-text">Can be Found on:</h3>
                        {/* Google Play Apple etc */}
                        {portfolio.marketingInfo.appleLink && (
                     <a
                        href={ portfolio.marketingInfo.appleLink
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >

                        <img
                          className="apple-logo"
                          alt="Get it on App Store"
                          src="https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/Marketing%2Fappstore.png?alt=media&token=9c7d72e6-fc2e-4e6d-bfef-ed1b52065914"
                        />
                      </a>
                        )}
                        {portfolio.marketingInfo.androidLink && (
                          <a
                            href={portfolio.marketingInfo.androidLink}
                            target="_blank"
                          >
                            <img
                              className="google-logo" // Apply the google-logo class
                              alt="Get it on Google Play"
                              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                            />
                          </a>
                        )}
                      </div>
                    )}
                  </div>

                </div>

                
              </div>
            </div>
          </div>
        )}
        {/*<!-- End Case Study Details Area -->*/}
        {/*<!-- Other Portfolio Pieces -->*/}
        {latestPortfolios && latestPortfolios.length > 0 && (
          <div className="container">
            <div className="row justify-content-left">
              <div style={{ textAlign: "center" }}>
                <h4>Other Portfolio Pieces</h4>
              </div>

              {latestPortfolios.map((value, index) => (
                <div key={index} className="col-lg-4 col-md-6">
                  <div
                    className="single-services-box"
                    style={{ textAlign: "left" }}
                  >
                    <div className="icon" style={{ backgroundColor: '#f2f2f2', overflow: 'hidden' }}>
                      <Link
                        onClick={() => updateUI(value)}
                        to={`/portfoliodetail/${value.portfolioTitle}`}
                      >
                        <img
                          src={value.portfolioImageUrl}
                          alt="image"
                          style={{ width: "100%", height: "500px" }}
                        />
                      </Link>
                    </div>
                    <h3>
                      <Link
                        onClick={() => updateUI(value)}
                        to={`/portfoliodetail/${value.portfolioTitle}`}
                      >
                        {value.portfolioTitle}
                      </Link>
                    </h3>
                    <div>
                      {value.portfolioOverview.length > 210 && (
                        <p>{value.portfolioOverview.substring(0, 210)}...</p>
                      )}
                      {value.portfolioOverview.length <= 210 && (
                        <p>{value.portfolioOverview}...</p>
                      )}
                    </div>
                    <Link
                      onClick={() => updateUI(value)}
                      to={`/portfoliodetail/${value.portfolioTitle}`}
                      className="services-btn"
                    >
                      Explore Portfolio Item{" "}
                      <i className="ri-arrow-right-line"></i>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}


{advertisementFullWidth.advertisementTitle && advertisementFullWidth.isCompactAdvertisement == false &&
(
    <AdvertisementFullWidth

    appDescription={advertisementFullWidth.advertisementDescription}
    appStoreLink={advertisementFullWidth.advertisementLink}
    appName={advertisementFullWidth.advertisementTitle}
    backgroundColor={backgroundColor}
    textColor={textColor}
    screenshot={advertisementFullWidth.advertisementImageUrl}

/>
)
}

      </section>
    </React.Fragment>
  );
};

export default PortfolioDetail;
