import React from "react";
import { Link } from "react-router-dom";
import courses1 from "../../../styletheme/assets/images/courses/courses-1.jpg";
import image1 from "../../../styletheme/assets/images/courses/images-1.jpg";
import dateFormat from "dateformat";
import searchnotfound from "../../../styletheme/other/illustrations/searchnotfoundportfolio.png";

const PortfolioItemCard = ({
	
	portfolioImageUrl,
	portfolioTitle,
	portfolioOverview,
	path,
	onClick,
	itemCount,
}) => {
	return (
		<React.Fragment>
			{portfolioTitle && (
			<div className='col-md-6'>
					<div className='single-projects-box'>
						<div className='projects-image' style={{ backgroundColor: '#f2f2f2', overflow: 'hidden' }}>
							<Link to={path}>
							<img
								src={portfolioImageUrl}
								alt='image'
								style={{
									height: "500px",
									position: "relative",
								}}
								/>

							</Link>
						</div>

						<div className='projects-content'>
							<h3>
								<Link to={path}>{portfolioTitle}</Link>
							</h3>
							{portfolioOverview.length > 210 && (
								<p>{portfolioOverview.substring(0, 210)}...</p>
							)}
							{portfolioOverview.length <= 210 && <p>{portfolioOverview}...</p>}
							<Link to={path} className='projects-btn'>
								Explore Portfolio Item <i className='ri-arrow-right-line'></i>
							</Link>
						</div>
					</div>
				</div>
			)}
			{!portfolioTitle && ( //!portfolioTitle && portfolioTitle !== undefined && itemCount === 0 &&
				<section className='col-lg-8 col-md-12'>
					<Link
						to='/portfolio'
						onClick={onClick}
						className='btn btn-success d-flex justify-content-center'>
						<b>Clear Filter and Refresh Portfolio</b>
					</Link>
					<img src={searchnotfound} style={{ height: "100%", width: "100%" }} />
				</section>
			)}
		</React.Fragment>
	);
};

export default PortfolioItemCard;
