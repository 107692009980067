import React, { Component } from "react";
import shape1 from "../../styletheme/assets/images/services/shape-1.png";
import shape2 from "../../styletheme/assets/images/services/shape-2.png";
import shape3 from "../../styletheme/assets/images/services/shape-3.png";
import shape4 from "../../styletheme/assets/images/services/shape-4.png";

import { Link } from "react-router-dom";
import Form from "../common/reusuable/form";
import Input from "../common/reusuable/input";
import TextArea from "../common/reusuable/textarea";
import uuid from "react-uuid";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { createNewContactRequest } from "../../services/contactrequest/contactrequestservice";
import { handlePageViewStat } from "../../services/utils/pageViewStats";

class ContactMe extends Form {
	state = {
		data: {
			//contactRequestId: '',
			requestorName: "",
			requestorEmailAddress: "",
			requestorPhoneNumber: "",
			subject: "",
			messageDetail: "", //recordCreatedDate: ''
		},
		errors: {},
		baseState: {},
	};
	//methods
	schema = {
		// contactRequestId: Joi.string().optional(),
		requestorName: Joi.string().required().label("Requestor Name"),
		requestorEmailAddress: Joi.string().required().label("Email Address"),
		requestorPhoneNumber: Joi.string().required().label("Phone Number"),
		//requestorBudget: Joi.number().required().label('Budget'),
		subject: Joi.string().required().label("Subject"),
		messageDetail: Joi.string().required().label("Message Detail"),
	};

	//set initial state to reset form on successful submit
	constructor(props) {
		super(props);

		// preserve the initial state in a new object
		this.baseState = this.state; ///>>>>>>>>> note this one.
	}
	resetForm = () => {
		this.setState(this.baseState); ///>>>>>>>>> note this one.
	};

	doSubmit = async () => {
		try {
			const { data } = this.state;
			//call the api method

			const requestorName = data.requestorName;
			const requestorEmailAddress = data.requestorEmailAddress;
			const requestorPhoneNumber = data.requestorPhoneNumber;
			const requestorBudget = data.requestorBudget;
			const subject = data.subject;
			const messageDetail = data.messageDetail;

			//Need to send this to the api and get the token
			const contactRequest = {
				contactRequestId: uuid(), //set a random ID
				requestorName: requestorName,
				requestorEmailAddress: requestorEmailAddress,
				requestorPhoneNumber: requestorPhoneNumber,
				requestorBudget: requestorBudget,
				subject: subject,
				messageDetail: messageDetail,
			};

			await createNewContactRequest(contactRequest);

			//Thank user
			toast.success("Contact Request successfully received.");
			//get the current location
			// const {state} = this.props.location;
			//reset the form and initial state
			this.resetForm();
			//force a reload of the page
			//GO back to the video management
			//window.location = state ? state.from.pathname : "/videoperfmmanagement";
			//npm i react-uuid
		} catch (ex) {
			if (ex.response && ex.response.status === 400) {
				const errors = { ...this.state.errors };
				errors.videoTitle = ex.response.data;

				toast.error(ex.response.data);
				//Update the UI
				this.setState({ errors });
			}
		}
	};

	componentDidMount() {
		//set at the top of the screen
		window.scrollTo(0, 0);


		if (process.env.NODE_ENV === 'production') {
			handlePageViewStat("ContactMe","","","","N/A","","",
			0,0);
		 }

	}

	render() {
		const { data, errors } = this.state;
		return (
			<React.Fragment>
				<section className='services-area pt-100 pb-70'>
					{/*<!-- Start Page Banner Area -->*/}
					<div className='page-banner-area'>
						<div className='container'>
							<div className='page-banner-content'>
								<h2>Contact</h2>

								<ul>
									<li>
										<Link to='/'>Home</Link>
									</li>
									<li>Contact</li>
								</ul>
							</div>
						</div>

						<div
							className='page-banner-shape-1'
							data-speed='0.08'
							data-revert='true'>
							<img src={shape1} alt='image' />
						</div>
						<div
							className='page-banner-shape-2'
							data-speed='0.08'
							data-revert='true'>
							<img src={shape2} alt='image' />
						</div>
						<div
							className='page-banner-shape-3'
							data-speed='0.08'
							data-revert='true'>
							<img src={shape3} alt='image' />
						</div>
						<div
							className='page-banner-shape-4'
							data-speed='0.08'
							data-revert='true'>
							<img src={shape4} alt='image' />
						</div>
					</div>
					{/*<!-- End Page Banner Area -->*/}

					{/*<!-- Start Contact Area -->*/}
					<div className='contact-area ptb-100'>
						<div className='container'>
							<div className='contact-form'>
								<h3>Get in Touch</h3>

								<form onSubmit={this.handleSubmit}>
									<div className='row'>
										<div className='col-lg-6 col-md-6'>
											<Input
												name='requestorName'
												type='text'
												error={errors.requestorName}
												value={data.requestorName}
												placeholder='Name'
												label='Your Name'
												onChange={this.handleChange}
											/>
										</div>

										<div className='col-lg-6 col-md-6'>
											<Input
												name='requestorEmailAddress'
												type='email'
												error={errors.requestorEmailAddress}
												value={data.requestorEmailAddress}
												placeholder='Email Address'
												label='Email Address'
												onChange={this.handleChange}
											/>
										</div>

										<div className='col-lg-6 col-md-6'>
											<Input
												name='requestorPhoneNumber'
												type='phone'
												error={errors.requestorPhoneNumber}
												value={data.requestorPhoneNumber}
												placeholder='Phone Number'
												label='Phone Number'
												onChange={this.handleChange}
											/>
										</div>

										<div className='col-lg-6 col-md-6'>
											<Input
												name='subject'
												type='text'
												error={errors.subject}
												value={data.subject}
												placeholder='Subject'
												label='Subject'
												onChange={this.handleChange}
											/>
										</div>

										<div className='col-lg-12 col-md-12'>
											<TextArea
												name='messageDetail'
												type='text'
												error={errors.messageDetail}
												value={data.messageDetail}
												placeholder='Write your message here.'
												label='Message'
												onChange={this.handleChange}
											/>
										</div>

										<div className='col-lg-12 col-md-12'>
											{this.renderButton("Send Message")}
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
					{/*<!-- End Contact Area -->*/}

					{/*<!-- Start Contact Info Area -->*/}
					<div className='contact-info-area pb-100'>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-4 col-md-12'>
									<div className='contact-address'>
										<h3>Contact Address:</h3>
										<p>Knowledge is Key.</p>

										<ul className='address-info'>
											<li>
												<i className='ri-map-pin-line'></i>
												Maine, USA
											</li>
										</ul>

										<ul className='address-social'>
											<li>
												<a href='#' target='_blank'>
													<i className='ri-facebook-line'></i>
												</a>
											</li>
											<li>
												<a href='#' target='_blank'>
													<i className='ri-twitter-fill'></i>
												</a>
											</li>
											<li>
												<a href='#' target='_blank'>
													<i className='ri-linkedin-fill'></i>
												</a>
											</li>
											<li>
												<a href='#' target='_blank'>
													<i className='ri-messenger-line'></i>
												</a>
											</li>
										</ul>
									</div>
								</div>

								<div className='col-lg-8 col-md-12'>
									<div id='map'>
										<iframe
											src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880338.465121192!2d-71.23391772243846!3d45.170281476909224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb01dde1d538ad5%3A0xd679cc6f6720a8d0!2sMaine!5e0!3m2!1sen!2sus!4v1624119777091!5m2!1sen!2sus'
											width='600'
											height='450'
											styles='border:0;'
											allowfullscreen=''
											loading='lazy'></iframe>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/*<!-- End Contact Info Area -->*/}
				</section>
			</React.Fragment>
		);
	}
}

export default ContactMe;
