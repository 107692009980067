import React from 'react';

const AdvertisementFullWidth = ({ backgroundColor,textColor, screenshot, appName, appDescription, appStoreLink }) => {
  return (
    <section className="overview-section pt-3 pb-3"  style={{ backgroundColor: backgroundColor, color: textColor }}>
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6 mb-3">
            <div className="app-screenshot">
              <img src={screenshot} alt="App Screenshot" style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} />
            </div>
          </div>
          <div className="col-md-9 col-sm-16">
            <div className="app-info">
              <h3>{appName}</h3>
              <p>{appDescription.substring(0,400)}
              
              {appDescription.substring(0, 400).length < appDescription.length && (
                                  <React.Fragment>
                                    <span>...</span>
                                  </React.Fragment>
                                )}
              
              </p>
              <a href={appStoreLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Download on the App Store</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvertisementFullWidth;