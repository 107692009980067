import http from "../general/httpService";
import api from "../../constants/config.json";

//fetch_all_portfolioes
export function fetchAllPortfolioPieces() {
	const fullApiEndpoint =
		api.apiEndpoint + "/portfolioManagement/api/fetch_all_portfolioes";
	return http.get(fullApiEndpoint);
}
//fetch_portfolio_by_id/:portfolioId
export function fetchAllPortfolioPiecesById(portfolioId) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/portfolioManagement/api/fetch_portfolio_by_id/" +
		portfolioId;
	return http.get(fullApiEndpoint);
}

//fetch_portfolio_by_title/:portfolioTitle

export function fetchPortfolioPieceByTitle(portfolioTitle) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/portfolioManagement/api/fetch_portfolio_by_title/" +
		portfolioTitle;
	return http.get(fullApiEndpoint);
}

//need to find the portfolio by category

//fetch_all_portfolio_categories
export function fetchAllPortfolioCategories() {
	const fullApiEndpoint =
		api.apiEndpoint + "/portfolioManagement/api/fetch_all_portfolio_categories";
	return http.get(fullApiEndpoint);
}

//fetch_latest_portfolio_categories/:limit
export function fetchLatestPortfolioCategories(limit) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/portfolioManagement/api/fetch_latest_portfolio_categories/" +
		limit;
	return http.get(fullApiEndpoint);
}

//fetch find_portfolio_by_categoryName
export function findPortfolioByCategoryName(categoryName) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/portfolioManagement/api/find_portfolio_by_categoryName/" +
		categoryName;
	return http.get(fullApiEndpoint);
}

//fetch_latest_portfolio/:limit
export function fetchLatestPortfolios(limit) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/portfolioManagement/api/fetch_latest_portfolio/" +
		limit;
	return http.get(fullApiEndpoint);
}

//fetch_latest_portfolio_exclude_target/:portfolioId/:limit
export function fetchLatestPortfoliosAndExclude(portfolioId, limit) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/portfolioManagement/api/fetch_latest_portfolio_exclude_target/" +
		portfolioId +
		"/" +
		limit;
	return http.get(fullApiEndpoint);
}


//pagination

export function fetchPortfoliosByPagination(page, limit) {

	//console.log(`page: ${page} limit: ${limit}`);

	const fullApiEndpoint =
		api.apiEndpoint +
		"/portfolioManagement/api/fetch_portfolios_by_page/" +
		page +
		"/" +
		limit;
	return http.get(fullApiEndpoint);
}


//fetchPortfolioWithSimilarTitle
export function fetchPortfolioWithSimilarTitle(portfolioTitle) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/portfolioManagement/api/fetch_portfolios_with_similar_title/" +
		portfolioTitle;
	return http.get(fullApiEndpoint);
}