
import React from 'react'
import { Link } from 'react-router-dom';
import courses1 from "../../../styletheme/assets/images/courses/courses-1.jpg"
import image1 from "../../../styletheme/assets/images/courses/images-1.jpg"
import dateFormat from 'dateformat';
import searchnotfound from "../../../styletheme/other/illustrations/searchnotfoundblogs.png"


const BlogCard = ({blog}) =>{
    return (
        

        <div className="col-lg-6 col-md-6">
        {blog.blogImageUrl &&
            <div className="single-blog" style={{textAlign: "left"}}>
                <div className="blog-image" style={{ backgroundColor: '#f2f2f2', overflow: 'hidden' }}>
                    <Link to={`/blogdetail/${blog.blogTitle}`}><img src={blog.blogImageUrl} alt="image"/></Link>
                </div>

                <div className="blog-content">
                    <ul className="entry-meta">
                        <li className="tag">
                            
                            {blog.blogCategories && blog.blogCategories.length > 0 &&
                                <span>{blog.blogCategories[0].categoryName}</span>
                            }
                        </li>
                        <li>
                            <i className="ri-time-line"></i>
                            {dateFormat(blog.recordCreatedDate, "mmmm dS, yyyy")}
                        </li>
                        <li>
                            <i className="ri-message-2-line"></i>
                            (0) Comment
                        </li>
                    </ul>
                    <h3>
                        <Link to={`/blogdetail/${blog.blogTitle}`}>{blog.blogTitle}</Link>
                    </h3>
                    <p>
                        {blog.blogOverview}
                    </p>
                    <Link to={`/blogdetail/${blog.blogTitle}`} className="blog-btn">Read More <i className="ri-arrow-right-line"></i></Link>
                </div>
            </div>
        }   
        {
            !blog.blogImageUrl &&
            <section >
                <Link to="/blogs" className="btn btn-success d-flex justify-content-center"><b>Clear Filter and Refresh Blogs</b></Link>
                <img src={searchnotfound} style={{height: "100%", width: "100%"}}/>


           </section>

        }

    </div>
    );
}

export default BlogCard;
