import React, { Component, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../../styletheme/assets/logo/logowithwords.png";
import shape1 from "../../../styletheme/assets/images/services/shape-1.png";
import shape2 from "../../../styletheme/assets/images/services/shape-2.png";
import shape3 from "../../../styletheme/assets/images/services/shape-3.png";
import shape4 from "../../../styletheme/assets/images/services/shape-4.png";
import shape5 from "../../../styletheme/assets/images/services/shape-5.png";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import LatestTutorialFooter from "../cards/lastesttutorialfooter";
import "../../../styletheme/other/menu/gototop.css";

const GeneralFooter = ({ latestTutorials, latestBlogs }) => {

  const [showGoTop, setShowGoTop] = useState(false);

  function handleGoTopClick() {
   
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function handleScroll() {
    if (window.scrollY > 100) {
      setShowGoTop(true);
    } else {
      setShowGoTop(false);
    }
  }

  window.addEventListener('scroll', handleScroll);

  return (
    <React.Fragment>
   {/*<!-- Start Go Top Area -->*/}
   <div className='btn btn-primary' style={{position: "relative", left:"10px"}} onClick={() => {handleGoTopClick()}}>
   <i class="ri-arrow-up-s-line"></i>
           Top
        </div>
      {/*<!-- End Go Top Area -->*/}

      {/*<!-- Start Footer Area -->*/}
      <footer class="footer-area pt-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
              <div class="single-footer-widget">
                <div class="widget-logo">
                  <Link to="/">
                    <img src={logo} alt="image" />
                  </Link>
                </div>
                <p>Knowledge is Key</p>

                <ul class="widget-info">
                  <li>
                    <i class="ri-map-pin-line"></i>
                    Maine, USA
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-2 col-sm-3">
              <div class="single-footer-widget">
                <h3>Quick Link</h3>

                <ul class="footer-links-list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/aboutme">About Me</Link>
                  </li>
                  <li>
                    <Link to="/tutorials">Tutorials</Link>
                  </li>
                  <li>
                    <Link to="/portfolio">Portfolio</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/contactme">Let’s Talk</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-3 col-sm-4">
              <div class="single-footer-widget">
                <h3>Recent Tutorials</h3>
                {/*
                            <ul class="footer-links-list">
                                <li>
                                
                                </li>
                                <li><a href="about-1.html">About Us</a></li>
                                <li><a href="contact.html">Contact</a></li>
                                <li><a href="purchase-guide.html">Purchase Guide</a></li>
                                <li><a href="terms-of-service.html">Terms of Service</a></li>
                                <li><a href="faq.html">Faq’s</a></li>
                                <li><a href="privacy-policy.html">Privacy Policy</a></li>
                            </ul>*/}

                {/*Latest Tutorials start  */}
                <div className="widget widget_plod_posts_thumb">
                  {latestTutorials.map((value, index) => (
                    //make this into a template
                  <React.Fragment key={index}>
                      <LatestTutorialFooter
                          
                          authorName={value.authorName}
                          authorImageUrl={value.authorProfile.authorProfileImgUrl}
                          tutorialPromoImageUrl={value.tutorialPromoImage}
                          tutorialTitle={value.tutorialTitle}
                          tutorialOverview={value.tutorialOverview}
                          path={`/tutorialdetail/${value.tutorialTitle}`}
                          timeStamp={value.recordCreatedDate}
                          // categories={value.tutorialCategories[0].categoryName}

                          // onClick={updateUI(value)}
                        />
                    </React.Fragment>
                  ))}
                </div>
                {/*Latest Tutorials end  */}
              </div>
            </div>

            <div class="col-lg-3 col-sm-6">
              <div class="single-footer-widget">
                <h3>Recent Blogs</h3>

                {latestBlogs.map((value, index) => (
                  <article
                    key={index}
                    className="item"
                    style={{ width: "150px" }}
                  >
                    <Link
                      to={`/blogdetail/${value.blogTitle}`}
                      className="thumb"
                    >
                      <img
                        src={value.blogImageUrl}
                        className="fullimage cover bg1"
                        role="img"
                        style={{ height: "125px", width: "150px" }}
                      />
                    </Link>

                    <div className="info">
                      <p className="title usmall">
                        <Link to={`/blogdetail/${value.blogTitle}`}>
                          <p style={{ fontSize: "12px" }}>
                            <b>{value.blogTitle}</b>
                          </p>
                        </Link>
                      </p>

                      <p style={{ fontSize: "12px" }}>
                        {/*<i className='ri-time-line'></i>{" "}*/}
                        {dateFormat(value.recordCreatedDate, "mmmm dS, yyyy")}
                      </p>
                    </div>
                  </article>
                ))}
              </div>
            </div>

            <div class="col-lg-3 col-sm-6" hidden>
              <div class="single-footer-widget">
                <h3>Newsletter</h3>

                <div class="widget-newsletter">
                  <div class="newsletter-content">
                    <p>
                      Get updated when new blogs, apps and tutorials are
                      released.
                    </p>
                  </div>

                  <form class="newsletter-form" data-bs-toggle="validator">
                    <input
                      type="email"
                      class="input-newsletter"
                      placeholder="Enter email"
                      name="EMAIL"
                      required
                      autocomplete="off"
                    />

                    <button type="submit">
                      <i class="ri-send-plane-2-line"></i>
                    </button>
                    <div id="validator-newsletter" class="form-result"></div>
                  </form>
                </div>

                <ul class="widget-social">
                  <li>
                    <a href="#" target="_blank">
                      <i class="ri-facebook-line"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i class="ri-twitter-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i class="ri-linkedin-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <i class="ri-messenger-line"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="copyright-area">
          <div class="container">
            <div class="copyright-area-content">
              <p>
                Copyright &copy; {new Date().getFullYear()} Keijaoh. All Rights
                Reserved by
                <a href={ "https://keijaoh.com/" } target="_blank">
                  &nbsp;
                  Keijaoh
                </a>

          
              </p>

          
     
    
            </div>
          </div>
        </div>

        <div class="footer-shape-1" data-speed="0.08" data-revert="true">
          <img src={shape1} alt="image" />
        </div>
        <div class="footer-shape-2" data-speed="0.08" data-revert="true">
          <img src={shape2} alt="image" />
        </div>
        <div class="footer-shape-3" data-speed="0.08" data-revert="true">
          <img src={shape3} alt="image" />
        </div>
        <div class="footer-shape-4" data-speed="0.08" data-revert="true">
          <img src={shape4} alt="image" />
        </div>
        <div class="footer-shape-5" data-speed="0.08" data-revert="true">
          <img src={shape5} alt="image" />
        </div>

        
      </footer>
      {/*<!-- End Footer Area -->*/}

   
    </React.Fragment>
  );
};

export default GeneralFooter;
