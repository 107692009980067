import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  fetchAllBlogs,
  fetchBlogPieceByTitle,
  fetchLatestBlogsAndExclude,
} from "../../../services/blog/blogsService";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import shape1 from "../../../styletheme/assets/images/services/shape-1.png";
import shape2 from "../../../styletheme/assets/images/services/shape-2.png";
import shape3 from "../../../styletheme/assets/images/services/shape-3.png";
import shape4 from "../../../styletheme/assets/images/services/shape-4.png";
import { getAdvertisementByWebPage } from "../../../services/advertisements/advertisementService";
import AdvertisementCompact from "../../common/advertisement/advertisementCompact";
import AdvertisementFullWidth from "../../common/advertisement/AdvertisementFullWidth";
import { handlePageViewStat } from "../../../services/utils/pageViewStats";


const BlogDetail = (props) => {
  const history = useNavigate();
  const { blogTitle } = useParams();
  const [isLoading, setIsLoading] = useState();

  //theme mode start
  const isDarkMode = props.themeName === "theme-dark";
  
  const backgroundColor = isDarkMode ? '#333': '#f7f7f7'; 
  const textColor = isDarkMode ? '#fff': '#333';
 

  const [blog, setBlog] = useState({
    blogId: "",
    blogTitle: "",
    blogImageUrl: "",
    blogOverview: "",
    blogTags: [
      {
        tagId: "",
        tagName: "",
        tagDescription: "",
      },
    ],
    commentCount: 0,
    authorName: "",
    authorProfile: {
      authorName: "",
      authorProfileImgUrl: "",
      authorProfileOverview: "",
    },
    blogCategories: [
      {
        categoryName: "",
        categoryDescription: "",
      },
    ],
    blogSections: [
      {
        sectionId: "",
        sectionTitle: "",
        sectionExplanation: "",
        blogSectionSnippets: [
          {
            snippetTitle: "",
            snippetExplanation: "",
            snippetMedias: [
              {
                mediaId: "",
                mediaUrl: "",
                mediaCaption: "",
                mediaType: "",
              },
            ],
          },
        ],
      },
    ],
    references: [
      {
        referenceTitle: "",
        referenceYear: 0,
        referenceAuthors: [
          {
            authorName: "",
            authorProfileUrl: "",
            authorTitle: "",
          },
        ],
        referenceDetail: "",
      },
    ],
    isDraft: false,
  });

  const [latestBlogs, setLatestBlogs] = useState([
    {
      blogId: "",
      blogTitle: "",
      blogImageUrl: "",
      blogOverview: "",
      blogTags: [
        {
          tagId: "",
          tagName: "",
          tagDescription: "",
        },
      ],
      commentCount: 0,
      authorName: "",
      authorProfile: {
        authorName: "",
        authorProfileImgUrl: "",
        authorProfileOverview: "",
      },
      blogCategories: [
        {
          categoryName: "",
          categoryDescription: "",
        },
      ],
      blogSections: [
        {
          sectionId: "",
          sectionTitle: "",
          sectionExplanation: "",
          blogSectionSnippets: [
            {
              snippetTitle: "",
              snippetExplanation: "",
              snippetMedias: [
                {
                  mediaId: "",
                  mediaUrl: "",
                  mediaCaption: "",
                  mediaType: "",
                },
              ],
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: "",
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: "",
              authorProfileUrl: "",
              authorTitle: "",
            },
          ],
          referenceDetail: "",
        },
      ],
      isDraft: false,
    },
  ]);


 //remove mobile once i know it works
 const [advertisement, setAdvertisement] = useState({
  advertisementId: "",
  advertPlacement: {
    website: {
      websitePage: "",
    },
    mobileApp: {
      mobileAppPage: "",
    },
  },
  isCompactAdvertisement: false,
  advertisementImageUrl: "",
  advertisementLink: "",
  advertisementDescription: "",
  advertisementTitle: "",
  advertisementStartDate: "",
  advertisementEndDate: "",
  advertisementIsActive: false,
  recordCreatedDate: "",
});

const [advertisementFullWidth, setAdvertisementFullWidth] = useState({
  advertisementId: "",
  advertPlacement: {
    website: {
      websitePage: "",
    },
    mobileApp: {
      mobileAppPage: "",
    },
  },
  isCompactAdvertisement: false,
  advertisementImageUrl: "",
  advertisementLink: "",
  advertisementDescription: "",
  advertisementTitle: "",
  advertisementStartDate: "",
  advertisementEndDate: "",
  advertisementIsActive: false,
  recordCreatedDate: "",
});

  useEffect(() => {
    //set at the top of the screen
    window.scrollTo(0, 0);
    setIsLoading(true);


    if (process.env.NODE_ENV === 'production') {
      handlePageViewStat("BlogDetail",blogTitle,"","",props.themeName,"","",
      0,0);
   }

    getBlogDetail();

    getAdvertisements();

  }, []);


  const getAdvertisements = async () => {
    try {
  
      //Compact
      //get the advertisements from function getAdvertByWebPage(webpage,isCompactAdvertisement) 
       getAdvertisementByWebPage("blogdetail","true")
       .then((response) => {
        //console.log("Advertisement");
        const result = response.data;
       
  
        if(result !== "")
        {
  
          //console.log("Compact Advertisement ");
          //console.log(result);
          setAdvertisement(result);
        }
        else
        {
          //console.log("No Compact Advertisement Found");
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
  
  
      //get the fullwidth advertisement for tutorial detail page
      getAdvertisementByWebPage("blogdetail","false")
      .then((response) => {
      
       const result = response.data;
      
  
       if(result !== "")
       {
  
         //console.log("Full Width Advertisement");
         //console.log(result);
         setAdvertisementFullWidth(result);
       }
       else
       {
         //console.log("No Full Width Advertisement Found");
       }
       
     })
     .catch((error) => {
       console.log(error);
     });
  
  
    } catch (error) {
      console.log("Error fetching advertisements:", error);
    }
  };


  const getBlogDetail = async () => {
    if (blogTitle !== undefined) {
      //console.log("Search by " + blogTitle);
      const { data: blog } = await fetchBlogPieceByTitle(blogTitle);
      //console.log(blog);
      //console.log("Title: " + blog.blogId);
      if (blog !== undefined && blog.length !== 0) {
        setBlog(blog);
        setIsLoading(false);
        const limit = 3;

        const { data: latestBlogs } = await fetchLatestBlogsAndExclude(
          blog.blogId,
          limit
        );

        if (latestBlogs !== undefined) setLatestBlogs(latestBlogs);

        setIsLoading(false);
      } else
        toast.warning(
          "No Blog Item Found, Please select Blog To see all available."
        );

      setIsLoading(false);
    } else if (blogTitle === undefined) {
      toast.warning(
        "No Blog Item Found, Please select Blog To see all available."
      );

      setIsLoading(false);
    }
  };

  const updateBlogsUI = async (value) => {
    setIsLoading(true);
    history(`/blogs/${value.categoryName}`, {
      replace: true,
    });
  };

  const updateUI = async (value) => {
    setIsLoading(true);
    if (value !== undefined && value !== "") {
      history(`/blogdetail/${value.blogTitle}`, {
        replace: true,
      });

      const { data: blog } = await fetchBlogPieceByTitle(value.blogTitle);

      if (blog !== undefined && blog.length !== 0) {
        setBlog(blog);
        setIsLoading(false);
        const { data: latestBlogs } = await fetchAllBlogs();
        if (latestBlogs !== undefined) setLatestBlogs(latestBlogs);
      } else
        toast.warning(
          "No Blog Item Found, Please select Blog To see all available."
        );
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/*Start Preloader Area */}
      {isLoading && (
        <div className="preloader-area">
          <div className="spinner">
            <div className="inner">
              <div className="disc"></div>
              <div className="disc"></div>
              <div className="disc"></div>
            </div>
          </div>
        </div>
      )}
      {/* End Preloader Area -->*/}

      <section className="services-area pt-100 pb-70">
        {/*<!-- Start Page Banner Area -->*/}
        <div className="page-banner-area">
          <div className="container">
            <div className="page-banner-content max-width">
              <h2>{blog.blogTitle}</h2>

              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Blog Detail</li>
              </ul>
            </div>
          </div>

          <div
            className="page-banner-shape-1"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape1} alt="image" />
          </div>
          <div
            className="page-banner-shape-2"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape2} alt="image" />
          </div>
          <div
            className="page-banner-shape-3"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape3} alt="image" />
          </div>
          <div
            className="page-banner-shape-4"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape4} alt="image" />
          </div>
        </div>
        {/*<!-- End Page Banner Area -->*/}

        {/*<!-- Start Blog Details Area -->*/}
        <div className="blog-details-area pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="blog-details-desc">
                  <div className="article-image" style={{ backgroundColor: '#f2f2f2', overflow: 'hidden' }}>
                    <img src={blog.blogImageUrl} alt="image" />
                  </div>

                  <div className="article-content">
                    <ul className="entry-meta">
                      <li>
                        <i className="ri-user-line"></i>
                        <a href="#">
                          {blog.authorProfile && blog.authorProfile.authorName}
                        </a>
                      </li>
                      <li>
                        <i className="ri-time-line"></i>
                        {dateFormat(blog.recordCreatedDate, "mmmm dS, yyyy")}
                      </li>
                      <li>
                        <i className="ri-message-2-line"></i>
                        (0) Comment
                      </li>
                    </ul>

                    <p>{blog.blogOverview}</p>

                    {blog.blogSections.map((value, index) => (
                      <section key={index}>
                        <h3>{value.sectionTitle}</h3>
                        <p>{value.sectionExplanation}</p>
                        {value.blogSectionSnippets.map((value, index) => (
                          <section key={index}>
                            <h4>{value.snippetTitle}</h4>
                            <p>{value.snippetExplanation}</p>
                            {/* Sub Images or videos start*/}
                            <ul className="wp-block-gallery columns-3">
                              {value.snippetMedias.map((value, index) => (
                                <li key={index} className="blocks-gallery-item" style={{ backgroundColor: '#f2f2f2', overflow: 'hidden' }}>
                                  <figure>
                                    <img src={value.mediaUrl} alt="image" />
                                  </figure>
                                </li>
                              ))}
                            </ul>
                            {/* Sub Images or videos end*/}
                          </section>
                        ))}
                      </section>
                    ))}
                  </div>
                  {blog.authorProfile && (
                    <div className="article-author">
                      <img
                        src={blog.authorProfile.authorProfileImgUrl}
                        alt="image"
                        style={{ height: "120px", width: "120px" }}
                      />
                      <p>{blog.authorProfile.authorProfileOverview}</p>
                      <span>{blog.authorProfile.authorName}</span>
                    </div>
                  )}
                  <div className="article-footer">
                    <div className="article-tags">
                      {blog.blogTags.map((value, index) => (
                        <Link key={index} to={`/blogs/${value.tagName}`}>
                          {" "}
                          {value.tagName}{" "}
                        </Link>
                      ))}
                    </div>

                    <div className="article-share">
                      <ul className="social">
                        <li>
                          <span>Share On:&nbsp; </span>
                        </li>

                        <li>
                          <Link
                            to={{ pathname: "https://twitter.com/keijaoh" }}
                            target="_blank"
                          >
                            <i className="ri-twitter-fill"></i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname:
                                "https://www.linkedin.com/in/keijaoh-campbell/",
                            }}
                            target="_blank"
                          >
                            <i className="ri-linkedin-fill"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="article-tag">
                    {latestBlogs.length > 0 && <h4>Other Blogs</h4>}
                    <div className="row">
                      {latestBlogs.map((value, index) => (
                        <div key={index} className="col-lg-6 col-md-6">
                          <div className="single-blog">
                            <div className="blog-image" style={{ backgroundColor: '#f2f2f2', overflow: 'hidden' }}>
                              <Link
                                to={`/blogdetail/${value.blogTitle}`}
                                onClick={() => updateUI(value)}
                              >
                                <img src={value.blogImageUrl} alt="image" />
                              </Link>
                            </div>

                            <div className="blog-content">
                              <ul className="entry-meta">
                                <li className="tag">
                                  {value.blogCategories &&
                                    value.blogCategories.length > 0 && (
                                      <span>
                                        {value.blogCategories[0].categoryName}
                                      </span>
                                    )}
                                </li>
                                <li>
                                  <i className="ri-time-line"></i>
                                  {dateFormat(
                                    value.recordCreatedDate,
                                    "mmmm dS, yyyy"
                                  )}
                                </li>
                                <li>
                                  <i className="ri-message-2-line"></i>
                                  (0) Comment
                                </li>
                              </ul>
                              <h3>
                                <Link
                                  to={`/blogdetail/${value.blogTitle}`}
                                  onClick={() => updateUI(value)}
                                >
                                  {value.blogTitle}
                                </Link>
                              </h3>
                              <Link
                                to={`/blogdetail/${value.blogTitle}`}
                                onClick={() => updateUI(value)}
                                className="blog-btn"
                              >
                                Read More{" "}
                                <i className="ri-arrow-right-line"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="article-comments" hidden>
                    <h4>2 Comments</h4>

                    <div className="comments-list">
                      <img
                        src="assets/images/clients/clients-1.png"
                        alt="image"
                      />
                      <h5>Steven Smith</h5>
                      <span>April 24, 2021 at 10:59 AM</span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <a href="#" className="reply-btn">
                        <i className="ri-reply-fill"></i>
                      </a>
                    </div>

                    <div className="comments-list">
                      <img
                        src="assets/images/clients/clients-3.png"
                        alt="image"
                      />
                      <h5>James Anderson</h5>
                      <span>April 24, 2021 at 10:59 AM</span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <a href="#" className="reply-btn">
                        <i className="ri-reply-fill"></i>
                      </a>
                    </div>
                  </div>

                  <div className="article-leave-comment" hidden>
                    <h4>Leave a Comment</h4>

                    <form>
                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          <div className="form-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name*"
                            />
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email*"
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                          <div className="form-group">
                            <textarea
                              name="message"
                              className="form-control"
                              placeholder="Your Message"
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <button type="submit" className="default-btn">
                            Post A Comment{" "}
                            <i className="ri-arrow-right-line"></i>
                            <span></span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                {advertisementFullWidth.advertisementTitle && advertisementFullWidth.isCompactAdvertisement == false &&
                (
                    <AdvertisementFullWidth

                    appDescription={advertisementFullWidth.advertisementDescription}
                    appStoreLink={advertisementFullWidth.advertisementLink}
                    appName={advertisementFullWidth.advertisementTitle}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    screenshot={advertisementFullWidth.advertisementImageUrl}

                />
                )
                }

              </div>

              <div className="col-lg-4 col-md-12">
                {/* Side bar start*/}
                <aside className="widget-area">
                  <div className="widget widget_search" hidden>
                    <form className="search-form">
                      <input
                        type="search"
                        className="search-field"
                        placeholder="Search Something"
                      />
                      <button type="submit">
                        <i className="ri-search-line"></i>
                      </button>
                    </form>
                  </div>

         
                   {/* Compact Advertisement */}

                   {advertisement.advertisementTitle && advertisement.isCompactAdvertisement == true &&
                    <AdvertisementCompact
                        screenshot={advertisement.advertisementImageUrl}
                        backgroundColor={backgroundColor}
                        textColor={textColor}
                        sectionHeight={'320px'}
                        appName={advertisement.advertisementTitle}
                        appDescription={advertisement.advertisementDescription}
                        appStoreLink={advertisement.advertisementLink}
                    />
                    }
                      {/* Compact Advertisement */}

                  <div className="widget widget_tag_cloud">
                    <h3 className="widget-title">Blog Categories</h3>

                    <div className="tagcloud">
                      {blog.blogCategories.map((value, index) => (
                        <span key={index}>
                          <Link
                          
                            onClick={() => updateBlogsUI(value)}
                            key={index}
                            to={`/blogs/${value.categoryName}`}
                          >
                            {value.categoryName}
                          </Link>
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="widget widget_plod_posts_thumb">
                    <h3 className="widget-title">Recent Blogs</h3>

                    {latestBlogs.map((value, index) => (
                      <article key={index} className="item">
                        <Link
                          onClick={() => updateUI(value)}
                          to={`/blogdetail/${value.blogTitle}`}
                          className="thumb"
                        >
                          <img
                            src={value.blogImageUrl}
                            className="fullimage cover bg1"
                            role="img"
                          />
                        </Link>

                        <div className="info">
                          <h4 className="title usmall">
                            <Link
                              onClick={() => updateUI(value)}
                              to={`/blogdetail/${value.blogTitle}`}
                            >
                              {value.blogTitle}
                            </Link>
                          </h4>

                          <span>
                            <i className="ri-time-line"></i>{" "}
                            {dateFormat(
                              value.recordCreatedDate,
                              "mmmm dS, yyyy"
                            )}
                          </span>
                        </div>
                      </article>
                    ))}
                  </div>

                  <div className="widget widget_tag_cloud" hidden>
                    <h3 className="widget-title">Popular Tags</h3>

                    <div className="tagcloud">
                      <a href="#">Agency</a>
                      <a href="#">Branding</a>
                      <a href="#">Marketing</a>
                      <a href="#">Design</a>
                      <a href="#">Development</a>
                      <a href="#">Consulting</a>
                      <a href="#">Startup</a>
                      <a href="#">Popular</a>
                      <a href="#">WordPress</a>
                      <a href="#">Financial</a>
                      <a href="#">Branding</a>
                    </div>
                  </div>
                </aside>

                {/*Side bar end */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Blog Details Area -->*/}
      </section>
    </React.Fragment>
  );
};

export default BlogDetail;
