import React from "react";
import { Link } from "react-router-dom";
import courses1 from "../../../styletheme/assets/images/courses/courses-1.jpg";
import image1 from "../../../styletheme/assets/images/courses/images-1.jpg";
import dateFormat from "dateformat";

const LatestTutorialFooter = ({

  tutorialPromoImageUrl,
  tutorialTitle,
  path,
  timeStamp,
  onClick,
}) => {
  return (
    <article className="item" style={{ width: "150px" }}>
      <Link to={path} onClick={onClick} className="thumb">
        <img
          src={tutorialPromoImageUrl}
          className="fullimage cover bg1"
          style={{ height: "125px", width: "150px" }}
          role="img"
        ></img>
      </Link>

      <div className="info">
        <p className="title u small">
          <Link to={path} onClick={onClick}>
            <p style={{ fontSize: "12px" }}>
              <b>{tutorialTitle}</b>
            </p>
          </Link>
        </p>

        <p style={{ fontSize: "12px" }}>
          <i className="ri-time-line"></i>
          {dateFormat(timeStamp, "mmmm dS, yyyy")}
        </p>
      </div>
    </article>
  );
};

export default LatestTutorialFooter;
