import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//images
import shape1 from "../../styletheme/assets/images/main-banner/banner-one/shape-1.png";
import shape2 from "../../styletheme/assets/images/main-banner/banner-one/shape-2.png";
import shape3 from "../../styletheme/assets/images/main-banner/banner-one/shape-3.png";
import shape4 from "../../styletheme/assets/images/main-banner/banner-one/shape-4.png";

import logo from "../../styletheme/assets/logo/logo.png";

//scanning
import background from "../../styletheme/other/illustrations/scanning/BG.png";

import machinelearning from "../../styletheme/other/illustrations/scanning/machinelearning.png";
import { fetchAboutMe } from "../../services/about/aboutService";
import TutorialCard from "../common/cards/tutorialcard";
import { fetchLatestTutorials } from "../../services/tutorials/tutorialService";
import { fetchLatestPortfolios } from "../../services/portfolio/portfolioService";
import PortfolioItemCard from "../common/cards/portfolioitemcard";
import BlogCard from "../common/cards/blogcard";
import { fetchLatestBlogs } from "../../services/blog/blogsService";
import { getAdvertisementByWebPage } from "../../services/advertisements/advertisementService";
import AdvertisementFullWidth from "../common/advertisement/AdvertisementFullWidth";
import { handlePageViewStat } from "../../services/utils/pageViewStats";

const HomePage = (props) => {

  const [isLoading, setIsLoading] = useState();

  const isDarkMode = props.themeName === "theme-dark";
  //advert
  const backgroundColor = isDarkMode ? '#333': '#f7f7f7'; 
  const textColor = isDarkMode ? '#fff': '#333';


  const [aboutMe, setAboutMe] = useState({
    aboutId: "",
    heroImageUrl: "",
    aboutAuthorName: "",
    aboutOverview: "",
    technologiesKnown: [
      {
        technologyName: "",
        technologyImageUrl: "",
        technologyDescription: "",
        isTechTopThree: false,
      },
    ],
    recordCreatedDate: "",
  });

  const [latestTutorials, setLatestTutorials] = useState([
    {
      tutorialId: "",
      tutorialTitle: "",
      videoUploadDate: "",
      youTubeVideoCode: "",
      tutorialOverview: "",
      tutorialPromoImage: "",
      tutorialTags: [
        {
          tagId: "",
          tagName: "",
          tagDescription: "",
        },
      ],

      commentCount: 0,
      authorName: "",
      authorProfile: {
        authorName: "",
        authorProfileImgUrl: "",
        authorProfileOverview: "",
      },
      tutorialCategories: [
        {
          categoryName: "",
          categoryDescription: "",
        },
      ],
      tutorialSections: [
        {
          sectionId: "",
          sectionTitle: "",
          sectionSectionExplanation: "",
          sourceCodeSnippets: [
            {
              sourceCodeTitle: "",
              sourceCodeExplanation: "",
              sourceCodeDetail: "",
              languageId: "",
              languageName: "",
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: "",
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: "",
              authorProfileUrl: "",
              authorTitle: "",
            },
          ],
          referenceDetail: "",
        },
      ],
      recordCreatedDate: "",
      isDraft: false,
    },
  ]);

  const [latestPortfolioes, setLatestPortfolioes] = useState([
    {
      portfolioId: "",
      portfolioTitle: "",
      portfolioImageUrl: "",
      portfolioOverview: "",
      portfolioBackground: "",
      portfolioTags: [
        {
          tagId: "",
          tagName: "",
          tagDescription: "",
        },
      ],
      portfolioTechnologies: [
        {
          technologyId: "",
          technologyName: "",
          technologyDescription: "",
        },
      ],
      commentCount: 0,
      authorName: "",
      authorProfile: {
        authorName: "",
        authorProfileImgUrl: "",
        authorProfileOverview: "",
      },
      portfolioCategories: [
        {
          categoryName: "",
          categoryDescription: "",
        },
      ],
      portfolioOurProcessSection: [
        {
          sectionId: "",
          sectionTitle: "",
          sectionExplanation: "",
          portfolioSectionSnippets: [
            {
              snippetTitle: "",
              snippetExplanation: "",
              snippetMedias: [
                {
                  mediaId: "",
                  mediaUrl: "",
                  mediaCaption: "",
                  mediaType: "",
                },
              ],
            },
          ],
        },
      ],
      portfolioHighlightedFeatureSection: [
        {
          sectionId: "",
          sectionTitle: "",
          sectionExplanation: "",
          portfolioSectionSnippets: [
            {
              snippetTitle: "",
              snippetExplanation: "",
              snippetMedias: [
                {
                  mediaId: "",
                  mediaUrl: "",
                  mediaCaption: "",
                  mediaType: "",
                },
              ],
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: "",
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: "",
              authorProfileUrl: "",
              authorTitle: "",
            },
          ],
          referenceDetail: "",
        },
      ],
      marketingInfo: {
        mediaType: "",
        mediaUrl: "",
        webLink: "",
        androidLink: "",
        appleLink: "",
        youtubeChannelLink: "",
        facebookPageLink: "",
        twitterProfileLink: "",
      },
      recordCreatedDate: "",
      isDraft: false,
    },
  ]);

  const [latestBlogs, setLatestBlogs] = useState([
    {
      blogId: "",
      blogTitle: "",
      blogImageUrl: "",
      blogOverview: "",
      blogTags: [
        {
          tagId: "",
          tagName: "",
          tagDescription: "",
        },
      ],
      commentCount: 0,
      authorName: "",
      authorProfile: {
        authorName: "",
        authorProfileImgUrl: "",
        authorProfileOverview: "",
      },
      blogCategories: [
        {
          categoryName: "",
          categoryDescription: "",
        },
      ],
      blogSections: [
        {
          sectionId: "",
          sectionTitle: "",
          sectionExplanation: "",
          blogSectionSnippets: [
            {
              snippetTitle: "",
              snippetExplanation: "",
              snippetMedias: [
                {
                  mediaId: "",
                  mediaUrl: "",
                  mediaCaption: "",
                  mediaType: "",
                },
              ],
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: "",
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: "",
              authorProfileUrl: "",
              authorTitle: "",
            },
          ],
          referenceDetail: "",
        },
      ],
      isDraft: false,
    },
  ]);

  const [advertisementFullWidth, setAdvertisementFullWidth] = useState({
    advertisementId: "",
    advertPlacement: {
      website: {
        websitePage: "",
      },
      mobileApp: {
        mobileAppPage: "",
      },
    },
    isCompactAdvertisement: false,
    advertisementImageUrl: "",
    advertisementLink: "",
    advertisementDescription: "",
    advertisementTitle: "",
    advertisementStartDate: "",
    advertisementEndDate: "",
    advertisementIsActive: false,
    recordCreatedDate: "",
  });

  useEffect(() => {
    setIsLoading(true);

    //set at the top of the screen
    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
			handlePageViewStat("HomePage","","","","N/A","","",
			0,0);
		}

    getAboutMe();
    getLatestTutorials();
    getLatestPortfolios();

    getLatestBlogs();

    getAdvertisements();


  }, []);



    //advertisement
    const getAdvertisements = async () => {
      try {
    
    
        //get the fullwidth advertisement for tutorial detail page
        getAdvertisementByWebPage("homepage","false")
        .then((response) => {
        
         const result = response.data;
        
    
         if(result !== "")
         {
    
           //console.log("Full Width Advertisement");
           //console.log(result);
           setAdvertisementFullWidth(result);
         }
         else
         {
           //console.log("No Full Width Advertisement Found");
         }
         
       })
       .catch((error) => {
         console.log(error);
       });
    
    
      } catch (error) {
        console.log("Error fetching advertisements:", error);
      }
    };


  const getAboutMe = async () => {
    const { data: aboutMe } = await fetchAboutMe();

    if (aboutMe !== "") setAboutMe(aboutMe);
  };

  const getLatestTutorials = async () => {
    //fetch latest tutorials
    const { data: latestTutorials } = await fetchLatestTutorials(5);

    if (latestTutorials !== "") {
      setLatestTutorials(latestTutorials);

      setIsLoading(false);
    }
  };

  const getLatestPortfolios = async () => {
    //fetch latest portfolios
    const { data: latestPortfolioes } = await fetchLatestPortfolios(5);

    if (latestPortfolioes !== undefined && latestPortfolioes.length !== 0) {
      setLatestPortfolioes(latestPortfolioes);
      setIsLoading(false);
    }
  };

  const getLatestBlogs = async () => {
    //fetch the latest blogs
    const bloglimit = 5;
    const { data: latestBlogs } = await fetchLatestBlogs(bloglimit);

    if (latestBlogs !== undefined) {
      setLatestBlogs(latestBlogs);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/*Start Preloader Area */}
      {isLoading && (
        <div className="preloader-area">
          <div className="spinner">
            <div className="inner">
              <div className="disc"></div>
              <div className="disc"></div>
              <div className="disc"></div>
            </div>
          </div>
        </div>
      )}
      {/* End Preloader Area -->*/}

      <section className="services-area pt-100 pb-70">
        {/*<!-- Start Main Banner Area -->*/}
        <div className="main-banner-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="main-banner-content">
                  <div
                    className="tag wow fadeInLeft"
                    data-wow-delay="300ms"
                    data-wow-duration="1000ms"
                  >
                    <img
                      src={logo}
                      alt="image"
                      style={{ height: "30px", width: "30px" }}
                    />
                    Keijaoh Tutorials
                  </div>
                  <h1
                    className="wow fadeInLeft"
                    data-wow-delay="00ms"
                    data-wow-duration="1000ms"
                  >
                    Welcome to Keijaoh's Portfolio and Tutorial Website.
                  </h1>
                  <p
                    className="wow fadeInLeft"
                    data-wow-delay="100ms"
                    data-wow-duration="1000ms"
                  >
                    On this website, I seek to showcase samples of my work and
                    give back to the developer community by providing free
                    educational content.
                  </p>

                  <div className="banner-btn">
                    <Link
                      to="/aboutme"
                      className="default-btn wow fadeInRight"
                      data-wow-delay="200ms"
                      data-wow-duration="1000ms"
                    >
                      About Me <i className="ri-arrow-right-line"></i>
                      <span></span>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="main-banner-animation-image">
           
                  <img
                    src={machinelearning}
                    className="wow fadeInDown"
                    data-wow-delay="1300ms"
                    data-wow-duration="1000ms"
                    alt="image"
                    data-speed="0.06"
                    data-revert="true"
                  />

                  <img src={background} alt="image" />
                </div>
              </div>
            </div>
          </div>

          <div className="main-banner-shape-1">
            <img src={shape1} alt="image" />
          </div>
          <div className="main-banner-shape-2">
            <img src={shape2} alt="image" />
          </div>
          <div className="main-banner-shape-3">
            <img src={shape3} alt="image" />
          </div>
          <div className="main-banner-shape-4">
            <img src={shape4} alt="image" />
          </div>
        </div>
        {/*<!-- End Main Banner Area -->*/}
        {/*<!-- Author Info start -->*/}
        {aboutMe && (
          <div className="blog-details-desc">
            <div className="article-author ">
              <h2>
                Who is <i>{aboutMe.aboutAuthorName}</i> ?
              </h2>
              <img
                src={aboutMe.heroImageUrl}
                alt="image"
                style={{ height: "120px", width: "120px" }}
              />
              <span></span>
              {aboutMe.aboutOverview.length > 500 && (
                <p>{aboutMe.aboutOverview.substring(0, 500)} ...</p>
              )}
              {aboutMe.aboutOverview.length < 500 && (
                <p>{aboutMe.aboutOverview}</p>
              )}
              <div className="banner-btn">
                <Link
                  to="/aboutme"
                  className="default-btn wow fadeInRight"
                  data-wow-delay="200ms"
                  data-wow-duration="1000ms"
                >
                  About Me <i className="ri-arrow-right-line"></i>
                  <span></span>
                </Link>
              </div>
            </div>
          </div>
        )}
        {/*<!-- Author Info end -->*/}
        {/*<!-- Start Latest Tutorials Area -->*/}
        <div className="services-area pt-100 pb-70">
          <div className="container">
            <div className="section-title">
              <h2>Latest Tutorials</h2>
              <div className="row">
                {latestTutorials.map((value, index) => (
                  <React.Fragment key={index}>
                    <TutorialCard
                    
                      authorName={value.authorName}
                      authorImageUrl={value.authorProfile.authorProfileImgUrl}
                      tutorialPromoImageUrl={value.tutorialPromoImage}
                      tutorialTitle={value.tutorialTitle}
                      tutorialOverview={value.tutorialOverview}
                      path={`/tutorialdetail/${value.tutorialTitle}`}
                      timeStamp={value.recordCreatedDate}
                    />
                    </React.Fragment>
                ))}
              </div>
            </div>
            <div className="section-title">
              <h2>Latest Portfolio Pieces</h2>
              <div className="row">
                {latestPortfolioes.map((value, index) => (
                  <React.Fragment key={index}>
                    <PortfolioItemCard
                    
                      portfolioImageUrl={value.portfolioImageUrl}
                      portfolioTitle={value.portfolioTitle}
                      portfolioOverview={value.portfolioOverview}
                      path={`/portfoliodetail/${value.portfolioTitle}`}
                      itemCount={latestPortfolioes.length}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="section-title">
              <h2>Latest Blogs</h2>
              <div className="row">
                {latestBlogs.map((value, index) => (
                  <React.Fragment key={index}>

                    <BlogCard blog={value} />
                  </React.Fragment>
                ))}
              </div>
            </div>


            {advertisementFullWidth.advertisementTitle && advertisementFullWidth.isCompactAdvertisement == false &&
                (
                    <AdvertisementFullWidth

                    appDescription={advertisementFullWidth.advertisementDescription}
                    appStoreLink={advertisementFullWidth.advertisementLink}
                    appName={advertisementFullWidth.advertisementTitle}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    screenshot={advertisementFullWidth.advertisementImageUrl}

                />
                )
                }

          </div>

          <div
            className="services-shape-2"
            data-speed="0.06"
            data-revert="true"
          >
            <img src={shape2} alt="image" />
          </div>
          <div
            className="services-shape-3"
            data-speed="0.06"
            data-revert="true"
          >
            <img src={shape3} alt="image" />
          </div>
          <div
            className="services-shape-4"
            data-speed="0.06"
            data-revert="true"
          >
            <img src={shape4} alt="image" />
          </div>
        </div>
        {/*<!-- End Services Area -->*/}
      </section>
    </React.Fragment>
  );
};
export default HomePage;
