import React from "react";
import { Link } from "react-router-dom";
import courses1 from "../../../styletheme/assets/images/courses/courses-1.jpg";
import image1 from "../../../styletheme/assets/images/courses/images-1.jpg";

const CategoriesGroupList = ({ items, textProperty, valueProperty, selectedCategory, onItemSelect }) => {
  return (
    <React.Fragment>
      <div className="widget widget_tag_cloud ">
        <h3 className="widget-title">Tutorial Categories</h3>

        <div className="tagcloud">
          {items.map((item) => (
            <a
              key={item[valueProperty]}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onItemSelect(item);
              }}
              className={item === selectedCategory ? "current" : ""}
            >
              <b>{item[textProperty]}</b>
            </a>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

CategoriesGroupList.defaultProps = {
  textProperty: "categoryName",
  valueProperty: "categoryId",
};

export default CategoriesGroupList;