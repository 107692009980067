
import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import shape1 from "../../styletheme/assets/images/services/shape-1.png"
import shape2 from "../../styletheme/assets/images/services/shape-2.png"
import shape3 from "../../styletheme/assets/images/services/shape-3.png"
import shape4 from "../../styletheme/assets/images/services/shape-4.png"
import errorimg from "../../styletheme/assets/images/404.png"

class NotFound extends Component
{

    render(){

        return(
            <React.Fragment>

{/*<!-- Start Page Banner Area -->*/}
        <div class="page-banner-area">
            <div class="container">
                <div class="page-banner-content">
                    <h2>404 Error</h2>

                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>404 Error</li>
                    </ul>
                </div>
            </div>

            <div class="page-banner-shape-1" data-speed="0.08" data-revert="true">
                <img src={shape1} alt="image"/>
            </div>
            <div class="page-banner-shape-2" data-speed="0.08" data-revert="true">
                <img src={shape2} alt="image"/>
            </div>
            <div class="page-banner-shape-3" data-speed="0.08" data-revert="true">
                <img src={shape3} alt="image"/>
            </div>
            <div class="page-banner-shape-4" data-speed="0.08" data-revert="true">
                <img src={shape4} alt="image"/>
            </div>
        </div>
        {/*<!-- End Page Banner Area -->*/}
        
        {/*<!-- Start Error Area -->*/}
		<div class="error-area ptb-100">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div style={{textAlign: "center"}}>
                        <Link to="/" class="default-btn">Go To Home <i class="ri-arrow-right-line"></i><span></span></Link>
                        <br/>
                        </div>
                        <div class="error-content">
                            
                            <img src={errorimg} alt="error"/>
        
                            <h3>Error 404 : page not found</h3>
                            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
        
                            <Link to="/" class="default-btn">Go To Home <i class="ri-arrow-right-line"></i><span></span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*<!-- End Error Area -->*/}
            </React.Fragment>

    )
    }
}

export default NotFound;