import React from "react";
import { Link } from "react-router-dom";
import courses1 from "../../../styletheme/assets/images/courses/courses-1.jpg";
import image1 from "../../../styletheme/assets/images/courses/images-1.jpg";
import dateFormat from "dateformat";

const LatestTutorialCard = ({

  tutorialPromoImageUrl,
  tutorialTitle,
  path,
  timeStamp,
  onClick,
}) => {
  return (
    <article className="item">
      <Link to={path} onClick={onClick} className="thumb">
        <img
          src={tutorialPromoImageUrl}
          className="fullimage cover bg1"
          role="img"
        ></img>
      </Link>

      <div className="info">
        <p className="title usmall">
          <Link to={path} onClick={onClick}>
            <p>
              <b>{tutorialTitle}</b>
            </p>
          </Link>
        </p>

        <span>
          <i className="ri-time-line"></i>
          {dateFormat(timeStamp, "mmmm dS, yyyy")}
        </span>
      </div>
    </article>
  );
};

export default LatestTutorialCard;
