import React, { Component, useEffect, useState } from "react";
import CodeSnippet from "../../common/sourcecode/codesnippet";
import { MathComponent } from "mathjax-react";
import shape1 from "../../../styletheme/assets/images/services/shape-1.png";
import shape2 from "../../../styletheme/assets/images/services/shape-2.png";
import shape3 from "../../../styletheme/assets/images/services/shape-3.png";
import shape4 from "../../../styletheme/assets/images/services/shape-4.png";
import shape5 from "../../../styletheme/assets/images/services/shape-5.png";
import bar from "../../../styletheme/assets/images/overview/bar.png";
import courseimage from "../../../styletheme/assets/images/courses/courses-details.jpg";
import Iframe from "react-iframe";
import {
  fetchTutorialByTitle,
  fetchLatestTutorials,
} from "../../../services/tutorials/tutorialService";

import { Link, Navigate, useNavigate, useParams } from "react-router-dom";

import dateFormat from "dateformat";
import { PrismCode } from "../../common/sourcecode/prismcode";
import TutorialSideBar from "../sidebar/tutorialsidebar";

import LatestTutorialCard from "../../common/cards/latesttutorialcard";
import CommentListCard from "../../common/listcard/commentlistcard";
import {
  fetchTutorialCommentByTutorialId,
  fetchTutorialCommentCount,
} from "../../../services/comments/commentService";

import { fetchAboutAuthorByName } from "../../../services/about/aboutService";

import "../../../styletheme/other/advertisement/wideAdvertStyle.css";

import Latex from "react-latex";
import AdvertisementFullWidth from "../../common/advertisement/AdvertisementFullWidth";
import {
  getAdvertByWebPage,
  getAdvertisementByWebPage,
} from "../../../services/advertisements/advertisementService";
import { handlePageViewStat } from "../../../services/utils/pageViewStats";
//Test this to render the data sent from the app https://www.npmjs.com/package/mathjax-react
//TODO next

const TutorialDetail = (props) => {
  const history = useNavigate();
  const { tutorialTitle } = useParams();
  const [isLoading, setIsLoading] = useState();

  //theme mode start
  const isDarkMode = props.themeName === "theme-dark";

  const backgroundColor = isDarkMode ? "#333" : "#f7f7f7";
  const textColor = isDarkMode ? "#fff" : "#333";
  const sectionHeight = "320px";

  const [authorProfile, setAuthorProfile] = useState({
    heroImageUrl: "",
    aboutAuthorName: "",
    aboutOverview: "",
    technologiesKnown: [
      {
        technologyName: "",
        technologyImageUrl: "",
        technologyDescription: "",
      },
    ],
    recordCreatedDate: "",
  });

  //future
  const [comments, setComments] = useState([
    {
      commentId: "",
      tutorialId: "",
      blogId: "",
      commentorId: "",
      commentorImageUrl: "",
      commentorName: "",
      commentorResponse: "",
      recordCreatedDate: "",
      nestedReply: [
        {
          commentorId: "",
          commentorImageUrl: "",
          commentorName: "",
          commentorResponse: "",
        },
      ],
    },
  ]);

  const [tutorial, setTutorial] = useState({
    tutorialId: "",
    tutorialTitle: "",
    videoUploadDate: "",
    youTubeVideoCode: "",
    tutorialOverview: "",
    tutorialPromoImage: "",
    tutorialTags: [
      {
        tagId: "",
        tagName: "",
        tagDescription: "",
      },
    ],

    commentCount: 0,
    authorName: "",
    authorProfile: {
      authorName: "",
      authorProfileImgUrl: "",
      authorProfileOverview: "",
    },
    tutorialCategories: [
      {
        categoryName: "",
        categoryDescription: "",
      },
    ],
    tutorialSections: [
      {
        sectionId: "",
        sectionTitle: "",
        sectionType: "",
        sectionPicUrl: "",
        mathEquationLogic: "",
        sectionSectionExplanation: "",
        sourceCodeSnippets: [
          {
            sourceCodeTitle: "",
            sourceCodeExplanation: "",
            sourceCodeDetail: "",
            languageId: "",
            languageName: "",
          },
        ],
      },
    ],
    references: [
      {
        referenceTitle: "",
        referenceYear: 0,
        referenceAuthors: [
          {
            authorName: "",
            authorProfileUrl: "",
            authorTitle: "",
          },
        ],
        referenceDetail: "",
      },
    ],
    recordCreatedDate: "",
    isDraft: false,
  });

  const [latestTutorials, setLatestTutorials] = useState([
    {
      tutorialId: "",
      tutorialTitle: "",
      videoUploadDate: "",
      youTubeVideoCode: "",
      tutorialOverview: "",
      tutorialPromoImage: "",
      tutorialTags: [
        {
          tagId: "",
          tagName: "",
          tagDescription: "",
        },
      ],

      commentCount: 0,
      authorName: "",
      authorProfile: {
        authorName: "",
        authorProfileImgUrl: "",
        authorProfileOverview: "",
      },
      tutorialCategories: [
        {
          categoryName: "",
          categoryDescription: "",
        },
      ],
      tutorialSections: [
        {
          sectionId: "",
          sectionTitle: "",
          sectionType: "",
          sectionSectionExplanation: "",
          mathEquationLogic: "",
          sectionPicUrl: "",
          sourceCodeSnippets: [
            {
              sourceCodeTitle: "",
              sourceCodeExplanation: "",
              sourceCodeDetail: "",
              languageId: "",
              languageName: "",
            },
          ],
        },
      ],
      references: [
        {
          referenceTitle: "",
          referenceYear: 0,
          referenceAuthors: [
            {
              authorName: "",
              authorProfileUrl: "",
              authorTitle: "",
            },
          ],
          referenceDetail: "",
        },
      ],
      recordCreatedDate: "",
      isDraft: false,
    },
  ]);

  const [commentCount, setCommentCount] = useState(0);

  //remove mobile once i know it works
  const [advertisement, setAdvertisement] = useState({
    advertisementId: "",
    advertPlacement: {
      website: {
        websitePage: "",
      },
      mobileApp: {
        mobileAppPage: "",
      },
    },
    isCompactAdvertisement: false,
    advertisementImageUrl: "",
    advertisementLink: "",
    advertisementDescription: "",
    advertisementTitle: "",
    advertisementStartDate: "",
    advertisementEndDate: "",
    advertisementIsActive: false,
    recordCreatedDate: "",
  });

  const [advertisementFullWidth, setAdvertisementFullWidth] = useState({
    advertisementId: "",
    advertPlacement: {
      website: {
        websitePage: "",
      },
      mobileApp: {
        mobileAppPage: "",
      },
    },
    isCompactAdvertisement: false,
    advertisementImageUrl: "",
    advertisementLink: "",
    advertisementDescription: "",
    advertisementTitle: "",
    advertisementStartDate: "",
    advertisementEndDate: "",
    advertisementIsActive: false,
    recordCreatedDate: "",
  });

  useEffect(() => {
    //set at the top of the screen
    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === "production") {
      handlePageViewStat(
        "TutorialDetail",
        "",
        tutorialTitle,
        "",
        props.themeName,
        "",
        "",
        0,
        0
      );
    }

    getTargetTutorial();

    getLatestTutorials();

    getAdvertisements();
  }, []);

  const getAdvertisements = async () => {
    try {
      //Compact
      //get the advertisements from function getAdvertByWebPage(webpage,isCompactAdvertisement)
      getAdvertisementByWebPage("tutorialdetail", "true")
        .then((response) => {
          //console.log("Advertisement");
          const result = response.data;

          if (result !== "") {
            //console.log("Compact Advertisement ");
            // console.log(result);
            setAdvertisement(result);
          } else {
            //console.log("No Compact Advertisement Found");
          }
        })
        .catch((error) => {
          console.log(error);
        });

      //get the fullwidth advertisement for tutorial detail page
      getAdvertisementByWebPage("tutorialdetail", "false")
        .then((response) => {
          const result = response.data;

          if (result !== "") {
            //console.log("Full Width Advertisement");
            //console.log(result);
            setAdvertisementFullWidth(result);
          } else {
            //console.log("No Full Width Advertisement Found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("Error fetching advertisements:", error);
    }
  };

  const getTargetTutorial = async () => {
    setIsLoading(true);
    const { data: tutorial } = await fetchTutorialByTitle(tutorialTitle);
    //console.log("Target Tutorial");
    //console.log(tutorial);
    if (tutorial !== "") {
      setTutorial(tutorial);
      const targetTutorialId = tutorial.tutorialId;
      getTutorialCommentCount(targetTutorialId);
      setIsLoading(false);
      //getTutorialComments(targetTutorialId);
      //author name from tutorial
      const authorName = tutorial.authorName;

      getAuthorProfileByName(authorName);
    }
  };

  const getAuthorProfileByName = async (authorName) => {
    const { data: authorProfile } = await fetchAboutAuthorByName(authorName);
    //console.log(authorProfile);
    if (authorProfile !== "") {
      setAuthorProfile(authorProfile);
    }
  };

  const getLatestTutorials = async () => {
    setIsLoading(true);
    //fetch latest
    const { data: latestTutorials } = await fetchLatestTutorials(5);

    if (latestTutorials !== "") {
      setLatestTutorials(latestTutorials);
      setIsLoading(false);
    }
  };

  const getTutorialCommentCount = async (targetTutorialId) => {
    //find comments
    const { data: commentCount } = await fetchTutorialCommentCount(
      targetTutorialId
    );
    //console.log(commentCount);
    if (commentCount !== null) setCommentCount(commentCount);
  };

  const getTutorialComments = async (targetTutorialId) => {
    const { data: comments } = await fetchTutorialCommentByTutorialId(
      targetTutorialId
    );

    if (comments !== "" && comments.length > 0) setComments(comments);
  };

  const updateUI = async (value) => {
    setIsLoading(true);

    history(`/tutorialdetail/${value.tutorialTitle}`, {
      replace: true,
    });
    //set at the top of the screen
    //alert(value.tutorialTitle);
    window.scrollTo(0, 0);
    const { data: tutorial } = await fetchTutorialByTitle(value.tutorialTitle);

    //update the tutorial by setting the state
    if (tutorial.tutorialTitle != "" && tutorial.tutorialTitle !== undefined) {
      setTutorial(tutorial);

      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/*Start Preloader Area */}
      {isLoading && (
        <div className="preloader-area">
          <div className="spinner">
            <div className="inner">
              <div className="disc"></div>
              <div className="disc"></div>
              <div className="disc"></div>
            </div>
          </div>
        </div>
      )}
      {/* End Preloader Area -->*/}

      <section className="services-area pt-100 pb-70">
        {/*<!-- Start Page Banner Area -->*/}
        <div className="page-banner-area">
          <div className="container">
            <div className="page-banner-content">
              <section>
                <h2>{tutorial.tutorialTitle}</h2>
                <ul className="courses-box-footer">
                  <li>
                    <i className="ri-calendar-2-line"></i>{" "}
                    {dateFormat(tutorial.recordCreatedDate, "mmmm dS, yyyy")}
                  </li>
                  <li>
                    {tutorial.tutorialCategories && (
                      <React.Fragment>
                        <i className="ri-price-tag-3-line"></i>{" "}
                        {tutorial.tutorialCategories.map((value, index) => (
                          <React.Fragment>
                            <span>{value.categoryName}</span>
                            {index < tutorial.tutorialCategories.length - 1 && (
                              <span>, </span>
                            )}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    )}
                  </li>
                </ul>
                <br />
              </section>

              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Tutorial Details</li>
              </ul>
            </div>
          </div>

          <div
            className="page-banner-shape-1"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape1} alt="image" />
          </div>
          <div
            className="page-banner-shape-2"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape2} alt="image" />
          </div>
          <div
            className="page-banner-shape-3"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape3} alt="image" />
          </div>
          <div
            className="page-banner-shape-4"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape4} alt="image" />
          </div>
        </div>
        {/*<!-- End Page Banner Area -->*/}

        {/*<!-- Start Courses Details Area -->*/}
        <div className="blog-details-area pt-100 pb-100">
          <div className="container">
            <div /*className="courses-details-image"*/>
              {/*<img src={courseimage} alt="image" style={{height: "1920px", width: "1000px"}}/>*/}
              {tutorial.youTubeVideoCode == "N/A" && (
                <img
                  src={tutorial.tutorialPromoImage}
                  alt="image"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "400px",
                    width: "auto",
                    height: "auto",
                    objectFit: "contain",
                    display: "block",
                  }}
                />
              )}
              {tutorial.youTubeVideoCode != "N/A" && (
                <Iframe
                  url={
                    "https://www.youtube.com/embed/" + tutorial.youTubeVideoCode
                  }
                  width="100%"
                  height="500px"
                  id="youTubeVideoCode"
                  className="rounded-top"
                  display="initial"
                  frameborder="0"
                  allowfullscreen
                  position="relative"
                />
              )}
            </div>

            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="courses-details-desc">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="overview"
                      role="tabpanel"
                    >
                      <div className="courses-overview">
                        <h3>Tutorial Overview</h3>
                        <p>{tutorial.tutorialOverview}</p>
                      </div>
                    </div>

                    <div id="overview" role="tabpanel">
                      {tutorial.tutorialSections.map((section, index) => (
                        //if Image
                        <React.Fragment>
                          {section.sectionType == "Image" && (
                            <img
                              src={section.sectionPicUrl}
                              alt="image"
                              style={{ height: "100%", width: "100%" }}
                            />
                          )}
                          {section.sectionType == "SourceCode" && (
                            //If Source Code
                            <div key={index} className="courses-overview">
                              <b>{section.sectionTitle}</b>
                              <p>{section.sectionSectionExplanation}</p>

                              {section.sourceCodeSnippets.map(
                                (source_code, index) => (
                                  <div key={index}>
                                    <React.Fragment>
                                      <h4>{source_code.sourceCodeTitle}</h4>
                                      <p>{source_code.sourceCodeExplanation}</p>
                                      <CodeSnippet
                                        code={source_code.sourceCodeDetail}
                                        language={source_code.languageId}
                                        id={`code-${Math.floor(
                                          Math.random() * 1000000
                                        )}-${Date.now()}`}
                                        plugins={[
                                          "line-numbers",
                                          "show-language",
                                        ]}
                                      />
                                    </React.Fragment>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                          {section.sectionType == "Paragraph" && (
                            <React.Fragment>
                              <b>{section.sectionTitle}</b>
                              <p>{section.sectionSectionExplanation}</p>

                              {section.sectionPicUrl != "" && (
                                <img
                                  src={section.sectionPicUrl}
                                  alt="image"
                                  style={{ height: "100%", width: "100%" }}
                                />
                              )}
                            </React.Fragment>
                          )}
                          {section.sectionType == "Math" && (
                            <React.Fragment>
                              <b>{section.sectionTitle}</b>
                              <p>{section.sectionSectionExplanation}</p>
                              {/*<img src={section.sectionPicUrl} alt="image" style={{height: "100%", width: "100%"}}/>*/}
                              {section.mathEquationLogic && (
                                <p>
                                  {/*<Latex>{section.mathEquationLogic}</Latex>*/}

                                  <MathComponent
                                    tex={section.mathEquationLogic}
                                  />
                                </p>
                              )}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ))}
                    </div>

                    <div>
                      {/*Author Section start*/}
                      <div className="blog-details-desc">
                        <div className="article-author">
                          <img
                            src={authorProfile.heroImageUrl}
                            alt="image"
                            style={{ height: "120px", width: "120px" }}
                          />
                          <span>{authorProfile.aboutAuthorName}</span>
                          <p>
                            {authorProfile.aboutOverview && (
                              <>
                                {authorProfile.aboutOverview.substring(0, 215)}
                                {authorProfile.aboutOverview.substring(0, 215)
                                  .length <
                                  authorProfile.aboutOverview.length && (
                                  <React.Fragment>
                                    <span>...</span>
                                  </React.Fragment>
                                )}
                              </>
                            )}
                          </p>
                        </div>

                        <div className="article-footer">
                          {tutorial.tutorialCategories && (
                            <React.Fragment>
                              <div className="article-tags">
                                {tutorial.tutorialCategories.map(
                                  (value, index) => (
                                    <Link>
                                      <span>{value.categoryName}</span>
                                      {index <
                                        tutorial.tutorialCategories.length -
                                          1 && <span>, </span>}
                                    </Link>
                                  )
                                )}
                              </div>
                            </React.Fragment>
                          )}

                          <div className="article-share">
                            <ul className="social">
                              <li>
                                <span>Share On: &nbsp;</span>
                              </li>

                              <li>
                                <a
                                  href={"https://twitter.com/keijaoh"}
                                  target="_blank"
                                >
                                  <i className="ri-twitter-fill"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href={
                                    "https://www.linkedin.com/in/keijaoh-campbell/"
                                  }
                                  target="_blank"
                                >
                                  <i className="ri-linkedin-fill"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href={
                                    "https://www.youtube.com/channel/UCVXYtm8jLpeQL3tY6FsYQ5Q"
                                  }
                                  target="_blank"
                                >
                                  <i className="ri-youtube-fill"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/*Author Section end*/}
                    </div>

                    <section className="blog-details-desc">
                      {/*Comments Section */}
                      {/* TODO Future Feature
                      <div className="article-comments">
                        <h4>{commentCount} Comments</h4>
                        {comments && comments.length > 0 && (
                          <React.Fragment>
                            {comments.map((value, index) => (
                              <React.Fragment>
                                <CommentListCard
                                  key={index}
                                  commentorId={value.commentorId}
                                  commentorName={value.commentorName}
                                  commentorImageUrl={value.commentorImageUrl}
                                  commentDetail={value.commentDetail}
                                  path={""}
                                  timeStamp={value.recordCreatedDate}
                                />
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        )}
                      </div>
                   
                      <div className="article-leave-comment" hidden>
                        <h4>Leave a Comment</h4>

                        <form>
                          <div className="row">
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name*"
                                />
                              </div>

                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Email*"
                                />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <textarea
                                  name="message"
                                  className="form-control"
                                  placeholder="Your Message"
                                ></textarea>
                              </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                              <button type="submit" className="default-btn">
                                Post A Comment{" "}
                                <i className="ri-arrow-right-line"></i>
                                <span></span>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>

                            */}
                    </section>
                  </div>
                </div>
              </div>
              {/*Side Bar Start exact this*/}
              <div className="col-lg-4 col-md-12">
                <section className="widget-area">
                  {/*Latest Tutorials start  */}
                  <div className="widget widget_plod_posts_thumb">
                    <h3 className="widget-title">Latest Tutorials</h3>
                    {latestTutorials.map((value, index) => (
                      //make this into a template
                      <LatestTutorialCard
                        key={index}
                        authorName={value.authorName}
                        authorImageUrl={value.authorProfile.authorProfileImgUrl}
                        tutorialPromoImageUrl={value.tutorialPromoImage}
                        tutorialTitle={value.tutorialTitle}
                        tutorialOverview={value.tutorialOverview}
                        path={`/tutorialdetail/${value.tutorialTitle}`}
                        timeStamp={value.recordCreatedDate}
                        //categories={value.tutorialCategories[0].categoryName}
                        onClick={() => updateUI(value)}
                      />
                    ))}
                  </div>
                  {/*Latest Tutorials end  */}
                  <TutorialSideBar
                    tutorial={tutorial}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    advertisement={advertisement}
                  />
                </section>
              </div>
              {/*Side Bar End*/}
            </div>
          </div>
        </div>
        {/*<!-- End Courses Details Area -->*/}

        {/*<!-- Start Overview Area -->
        <div className="overview-area pb-100">
          <div className="container">
            <div className="overview-box">
              <div className="overview-content">
                <h3>Have a tutorial you would like to see?</h3>

                <div className="overview-btn">
                  <Link to="/contactme" className="overview-btn-one">
                    Let's Talk
                  </Link>
                  <img src={bar} alt="image" />
                </div>
              </div>
            </div>
          </div>

          <div
            className="overview-shape-1"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape1} alt="image" />
          </div>
          <div
            className="overview-shape-2"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape2} alt="image" />
          </div>
          <div
            className="overview-shape-3"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape3} alt="image" />
          </div>
          <div
            className="overview-shape-4"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape4} alt="image" />
          </div>
          <div
            className="overview-shape-5"
            data-speed="0.08"
            data-revert="true"
          >
            <img src={shape5} alt="image" />
          </div>
        </div>
       <!-- End Overview Area -->*/}
        {/* Advertisement Section */}

        {/* Advertisement Section Start */}
        {/* App Info Section Start MAKE resuable*/}
        {/*}
<section className="overview-section pt-3 pb-3" style={{ backgroundColor }}>
  <div className="container">
    <div className="row">
      <div className="col-md-3 col-sm-6 mb-3">
        <div className="app-screenshot">
          <img src={screenshot} alt="App Screenshot" style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} />
        </div>
      </div>
      <div className="col-md-9 col-sm-16">
        <div className="app-info">
          <h3>{appName}</h3>
          <p>{appDescription.substring(0,400)}
          
          {appDescription.substring(0, 400).length < appDescription.length && (
                              <React.Fragment>
                                <span>...</span>
                              </React.Fragment>
                            )}
          
          </p>
          <a href={appStoreLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Download on the App Store</a>
        </div>
      </div>
    </div>
  </div>
</section>  
 backgroundColor, screenshot, appName, appDescription, appStoreLink 

 style={{ backgroundColor: '#333', color: '#fff' }}
*/}
        {/* check that advertisementFullWidth has values */}

        {advertisementFullWidth.advertisementTitle &&
          advertisementFullWidth.isCompactAdvertisement == false && (
            <AdvertisementFullWidth
              appDescription={advertisementFullWidth.advertisementDescription}
              appStoreLink={advertisementFullWidth.advertisementLink}
              appName={advertisementFullWidth.advertisementTitle}
              backgroundColor={backgroundColor}
              textColor={textColor}
              screenshot={advertisementFullWidth.advertisementImageUrl}
            />
          )}

        {/* End Advertisement Section */}
        {/* Advertisement Section End */}
      </section>
    </React.Fragment>
  );
};

export default TutorialDetail;
