import React from "react";
import Prism from "prismjs";
import "../../../styletheme/other/prism.css";

export class PrismCode extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  componentDidMount() {
    Prism.highlightAll();
  }
  componentDidUpdate() {
    //this.highlight()
    Prism.highlightAll();
  }
  highlight = () => {
    if (this.ref && this.ref.current) {
      Prism.highlightElement(this.ref.current);
    }
  };
  render() {
    const { code, plugins, language } = this.props;
    return (
      <pre className={plugins}>
        <code ref={this.ref} className={language}>
          {code.trim()}
        </code>
      </pre>
    );
  }
}
