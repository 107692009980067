import http from "../general/httpService";
import api from "../../constants/config.json";


export function getAdvertisementByWebPage(websitePage,isCompactAdvertisement) {
	const fullApiEndpoint =
		api.apiEndpoint +
		"/advertisementManagement/api/getadvertisementpage/" +
		websitePage  + "/" + isCompactAdvertisement.toString();
	return http.get(fullApiEndpoint);
}


